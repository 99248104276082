import { FilterMenu } from '@/components/FilterMenu';
import { queryClient } from '@/graphql/client';
import { CREATE_REPORT_V2, UPDATE_REPORT_V2 } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAccount } from '@/hooks/useAccount';
import { queryKey } from '@/hooks/useReports';
import { closeCreateReport, closeEditReport, selectProject, useLayout } from '@/stores/layout';
import { cn } from '@/utils/classname';
import { Button, Input, toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { FilterProps } from '../ReportPage';
import { SelectAnnotation } from './SelectAnnotation';
import { SelectProjectInput } from './SelectProjectInput';

interface CreateOrEditReportProps extends FilterProps {
  mode: 'create' | 'edit';
}

export const CreateOrEditReport = ({
  mode,
  enabledFilterMenu,
  setEnabledFilterMenu,
}: CreateOrEditReportProps) => {
  const accountId = useAccount().data?.accountByContext?.id;
  const projectId = useLayout(state => state.projectId);
  const projectName = useLayout(state => state.projectName);
  const description = useLayout(state => state.description);
  const annotationIds = useLayout(state => state.annotationIds);
  const reportId = useLayout(state => state.reportId);

  const [projectFilter, setProjectFilter] = useState<string>('');
  const [name, setName] = useState<string>(projectName ?? '');
  const [desc, setDesc] = useState<string>(description ?? '');

  const createReport = useMutation({
    mutationFn: () => {
      return request(CREATE_REPORT_V2, {
        accountId: accountId as string,
        projectId: projectId as string,
        name: name as string,
        description: desc,
        annotationIds: annotationIds,
      });
    },
    onError: error => {
      toast({
        type: 'warn',
        message: `${error}`,
        lifespan: 10000,
        clickToDismiss: true,
      });
      closeCreateReport();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toast({
        type: 'info',
        message: (dismiss: () => void) => {
          return (
            <>
              Success! Your report is generating. Look out for a confirmation email soon.{' '}
              <a className="underline typo-link-xs" onClick={dismiss}>
                Dismiss
              </a>
            </>
          );
        },
      });

      closeCreateReport();
    },
  });

  const handleCreate = useCallback(() => {
    createReport.mutate();
  }, [createReport]);

  const updateReport = useMutation({
    mutationFn: () => {
      return request(UPDATE_REPORT_V2, {
        reportId: reportId as string,
        name: name as string,
        description: desc,
        annotationIds: annotationIds,
      });
    },

    onError: error => {
      toast({
        type: 'warn',
        message: `${error}`,
        lifespan: 10000,
        clickToDismiss: true,
      });
      closeEditReport();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);

      toast({
        type: 'info',
        message: (dismiss: () => void) => {
          return (
            <>
              Success! Your report is updated.{' '}
              <a className="underline typo-link-xs" onClick={dismiss}>
                Dismiss
              </a>
            </>
          );
        },
      });

      closeEditReport();
    },
  });

  const handleSave = useCallback(() => {
    updateReport.mutate();
  }, [updateReport]);

  return (
    <div
      className={cn(
        'fixed left-0 top-0 z-1 h-full w-full flex items-center justify-center',
        ' bg-black bg-opacity-30',
      )}
    >
      <div
        className={cn(
          'fixed',
          'bottom-12',
          'top-12',
          'w-680px',
          'bg-neutral-100',
          'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
          'rounded-2',
          'left-50%',
          'transform-translate-x--50%',
          'p-6',
          'flex',
          'flex-col',
          'z-10',
        )}
      >
        <p className="color-neutral-800 typo-text-l">
          {mode === 'create' ? 'Create a new report' : 'Edit report'}
        </p>
        <p className="mt-3 color-neutral-800 typo-text-s">
          Enter details below to customise and generate a report.
        </p>

        <div className="mt-6 flex items-stretch gap-3">
          <div className="flex-1">
            <Input label="Report name" onChange={setName} value={name} />
          </div>
          <div className="z-3 flex-1">
            <SelectProjectInput
              projectFilter={projectFilter}
              projectId={projectId}
              selectProject={selectProject}
              setProjectFilter={setProjectFilter}
            />
          </div>
        </div>

        <div className="mt-3">
          <Input label="Description" onChange={setDesc} value={desc} />
        </div>

        <div className="relative mt-6 flex items-center justify-between">
          <p className="color-neutral-800 typo-text-m">Select annotations</p>
        </div>

        {enabledFilterMenu && <FilterMenu closeMenu={() => setEnabledFilterMenu(false)} />}

        <SelectAnnotation
          enabledFilterMenu={enabledFilterMenu}
          setEnabledFilterMenu={setEnabledFilterMenu}
        />

        <div className="mt-6 flex flex-row justify-between gap-3">
          <Button
            className="flex-1"
            onClick={() => {
              mode === 'create' ? closeCreateReport() : closeEditReport();
              setEnabledFilterMenu(false);
            }}
            size="s"
          >
            Cancel
          </Button>
          <Button
            className="flex-1"
            filled
            onClick={() => {
              mode === 'create' ? handleCreate() : handleSave();
            }}
            primary
            size="s"
          >
            {mode === 'create' ? 'Create' : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );
};
