import { gql } from './codegen';

// Login
export const LOG_IN = gql(`
  mutation Login($email: String, $password: String) {
    login(email: $email, password: $password) {
      bearerToken
      email
      accountId
    }
  }
`);

// Report
export const CREATE_REPORT_V2 = gql(`
  mutation CreateReportV2($accountId: ID!,
    $projectId: ID!,
    $name: String!,
    $description: String,
    $annotationIds: [ID]) {
    createReportV2(accountId: $accountId, projectId: $projectId, name: $name, description: $description, annotationIds: $annotationIds) {
      id
      accountId
      projectId
      name
      description
      annotationIds
      annotations {
        id
      }
      pdfGenerateEvents {
        status
        invokeId
        errorMessage
        requestedAt
        requestedByUserId
        pdfFileId
      }
      createdByUserId
      createdAt
      updatedByUserId
      updatedAt
      isDeleted
    }
  }
`);

export const UPDATE_REPORT_V2 = gql(`
  mutation UpdateReportV2($reportId: ID!,
    $name: String,
    $description: String,
    $annotationIds: [ID]) {
    updateReportV2(reportId: $reportId, name: $name, description: $description, annotationIds: $annotationIds) {
      id
      accountId
      projectId
      name
      description
      annotationIds
      annotations {
        id
      }
      pdfGenerateEvents {
        status
        invokeId
        errorMessage
        requestedAt
        requestedByUserId
        pdfFileId
      }
      createdByUserId
      createdAt
      updatedByUserId
      updatedAt
      isDeleted
    }
  }
`);

export const GENERATE_REPORT_V2_PDF = gql(`
  mutation GenerateReportPdfV2($reportId: ID!) {
    generateReportPdfV2(reportId: $reportId) {
      id
    }
  }
`);

export const DELETE_REPORT_V2 = gql(`
  mutation DeleteReportV2($reportId: ID!) {
    deleteReportV2(reportId: $reportId)
  }
`);
