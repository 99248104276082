import { FindIcon } from '@/components/Icon';
import { PolicyActionTypeInput, type Project, type ReportV2 } from '@/graphql/codegen/graphql';
import { useProjects } from '@/hooks/useProjects';
import { useProjectsWithPermissions } from '@/hooks/useProjectsWithPermissions';
import { useReports } from '@/hooks/useReports';
import { useUsers } from '@/hooks/useUsers';
import { openCreateReport } from '@/stores/layout';
import { cn } from '@/utils/classname';
import { Button, CheckBox, Input } from '@skand/ui';
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ChangeEvent, useMemo, useState } from 'react';
import { Actions } from './Table/Actions';
import { Cell } from './Table/Cell';
import { Header } from './Table/Header';
import { PdfReportStatus } from './PdfReportStatus';
import { ProjectsSearchResultsSummary } from '@/components/ProjectsSearchResultsSummary';
import { ReportTableResultsDisplay } from './ReportTableResultsDisplay';

const columnHelper = createColumnHelper<Solid<ReportV2, 'id' | 'name'>>();

export enum ReportsDisplayState {
  Loading = 'LOADING',
  SearchResultsEmpty = 'SEARCH_RESULTS_EMPTY',
  ReportsEmpty = 'REPORTS_EMPTY',
  EmptyListAndSearchResults = 'EMPTY_LIST_AND_SEARCH_RESULTS',
  Default = 'DEFAULT',
}

const getDisplayState = (
  isLoading: boolean,
  totalReportsLength: number,
  isUserSearching: boolean,
  searchQueriedReportsLength: number,
): ReportsDisplayState => {
  if (isLoading) {
    return ReportsDisplayState.Loading;
  }

  if (!totalReportsLength && !isUserSearching) {
    return ReportsDisplayState.ReportsEmpty;
  }

  if (!totalReportsLength && isUserSearching) {
    return ReportsDisplayState.EmptyListAndSearchResults;
  }

  if (!searchQueriedReportsLength && isUserSearching) {
    return ReportsDisplayState.SearchResultsEmpty;
  }

  if (searchQueriedReportsLength) {
    return ReportsDisplayState.Default;
  }

  return ReportsDisplayState.ReportsEmpty;
};

export const ReportTable = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const usersResponse = useUsers();
  const projectsResponse = useProjects();
  const reportsResponse = useReports();

  const projects = useMemo(() => {
    return (
      projectsResponse.data?.listProjectsByAccountContext?.filter(
        (item): item is SolidId<Project> => !!item?.id,
      ) || []
    );
  }, [projectsResponse.data?.listProjectsByAccountContext]);

  const searchQueriedReports = useMemo(() => {
    if (projectsResponse.isError || reportsResponse.isError) return [];

    return (
      reportsResponse.data?.listReportsV2ByAccountContext?.filter(
        (report): report is SolidName<SolidId<ReportV2>> =>
          !!report?.id &&
          !!report?.name &&
          report.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ) || []
    );
  }, [
    projectsResponse.isError,
    reportsResponse.data?.listReportsV2ByAccountContext,
    reportsResponse.isError,
    searchQuery,
  ]);

  const users = useMemo(() => {
    return usersResponse.data?.usersByAccountIdWithDeactivated || [];
  }, [usersResponse.data?.usersByAccountIdWithDeactivated]);

  const { checkPermissionsByProjectId } = useProjectsWithPermissions([
    PolicyActionTypeInput.Admin,
    PolicyActionTypeInput.Edit,
  ]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: header => (
          <div className="flex flex-1 items-center justify-start gap-12px whitespace-nowrap">
            <CheckBox
              checked={
                selectedRows.length > 0 && selectedRows.length === searchQueriedReports.length
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setSelectedRows(searchQueriedReports.map(row => row.id));
                } else {
                  setSelectedRows([]);
                }
              }}
            />
            <p className="color-neutral-800 typo-text-xs-em">REPORT NAME</p>
            <div
              className={cn('i-skand-dropdown color-neutral-400  cursor-pointer', {
                'text-blue-500 rotate-180	': header.column.getIsSorted() === 'asc',
                'text-red-500': header.column.getIsSorted() === 'desc',
                'text-gray-500 ': header.column.getIsSorted() === undefined,
              })}
              onClick={header.column.getToggleSortingHandler()}
            />
          </div>
        ),
        cell: ({ row }) => {
          const report = row.original;
          return (
            <div className="grid grid-cols-[auto_minmax(0,_2fr)] w-full items-center gap-3 text-sm color-neutral-800 typo-text-s">
              <CheckBox
                checked={selectedRows.includes(report.id)}
                onChange={e => {
                  if (e.target.checked) {
                    setSelectedRows(prevState => [...prevState, report.id]);
                  } else {
                    setSelectedRows(prevState => prevState.filter(id => id !== report.id));
                  }
                }}
              />
              <p className="truncate" title={report.name}>
                {report.name}
              </p>
            </div>
          );
        },
      }),

      columnHelper.accessor(
        row => {
          const project = projects.find(p => p.id === row.projectId);
          return project?.name;
        },
        {
          id: 'projectName',
          header: header => <Header header={header} title="PROJECT NAME" />,
          cell: ({ getValue }) => <Cell>{getValue()}</Cell>,
        },
      ),

      columnHelper.accessor(row => dayjs(row.createdAt), {
        id: 'createdAt',
        sortingFn: (a, b, id) => {
          const timeA = dayjs(a.getValue(id));
          const timeB = dayjs(b.getValue(id));
          return timeA.diff(timeB);
        },
        header: header => <Header header={header} title="DATE CREATED" />,
        cell: ({ getValue }) => <Cell>{getValue().format('DD/MM/YYYY')}</Cell>,
      }),
      columnHelper.accessor(row => dayjs(row.createdAt), {
        id: 'reportStatus',
        sortingFn: (a, b, id) => {
          const timeA = dayjs(a.getValue(id));
          const timeB = dayjs(b.getValue(id));
          return timeA.diff(timeB);
        },
        header: header => <Header header={header} title="PDF STATUS" />,
        cell: ({ row }) => (
          <PdfReportStatus
            pdfGenerateEvents={row.original.pdfGenerateEvents ?? []}
            rowId={row.original.id}
          />
        ),
      }),

      columnHelper.accessor(
        row => {
          const user = users.find(u => u?.id === row.createdByUserId);
          return user?.displayName ?? `${user?.firstName} ${user?.lastName}`;
        },
        {
          id: 'createdByUserId',
          header: header => <Header header={header} title="created by" />,
          cell: ({ getValue }) => <Cell>{getValue()}</Cell>,
        },
      ),

      columnHelper.accessor(row => dayjs(row.updatedAt), {
        id: 'updatedAt',
        header: header => <Header header={header} title="LAST UPDATED" />,
        sortingFn: (a, b, id) => {
          const timeA = dayjs(a.getValue(id));
          const timeB = dayjs(b.getValue(id));
          return timeA.diff(timeB);
        },
        cell: ({ getValue }) => <Cell>{getValue().format('DD/MM/YYYY hh:mm:ss a')}</Cell>,
      }),

      columnHelper.accessor(row => row, {
        id: 'actions',
        header: () => '',
        cell: ({ getValue, row }) => {
          const hasEditAccess =
            !!row.original.projectId && checkPermissionsByProjectId(row.original.projectId);

          return <Actions hasEditAccess={hasEditAccess} row={getValue()} />;
        },
      }),
    ],
    [selectedRows, searchQueriedReports, projects, users, checkPermissionsByProjectId],
  );

  const table = useReactTable({
    data: searchQueriedReports,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: { sorting },
    onSortingChange: setSorting,
  });

  const isLoading = projectsResponse.isLoading || reportsResponse.isLoading;
  const totalReportsLength = reportsResponse.data?.listReportsV2ByAccountContext?.length ?? 0;
  const isUserSearching = searchQuery.trim().length > 0;
  const searchQueriedReportsLength = searchQueriedReports.length;

  const resultsDisplayState = getDisplayState(
    isLoading,
    totalReportsLength,
    isUserSearching,
    searchQueriedReportsLength,
  );

  return (
    <div className="flex flex-1 flex-col">
      <div className="mt-3 flex justify-between">
        <p className="color-neutral-800 typo-text-s">Customise and generate project reports.</p>
        <div className="flex gap-2">
          <div className="relative flex flex-row items-center justify-center gap-2">
            {searchQuery.trim() && (
              <div className={cn('truncate w-480px ')}>
                <ProjectsSearchResultsSummary
                  dataLength={searchQueriedReports.length}
                  searchKey={searchQuery}
                />
              </div>
            )}
            <div className={cn('w-360px', !totalReportsLength && 'opacity-50')}>
              <Input
                disabled={!totalReportsLength}
                onChange={setSearchQuery}
                placeholder="Search for report names"
                tail={<FindIcon />}
                value={searchQuery}
              />
            </div>
          </div>
          <div>
            <Button className="h-full w-64px color-neutral-800" size="s">
              Filter
            </Button>
          </div>
        </div>
      </div>
      <ReportTableResultsDisplay
        columnLength={columns.length}
        openCreateReport={openCreateReport}
        resultsDisplayState={resultsDisplayState}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      >
        <>
          <div className="mt-6 w-full flex items-center b-1 b-neutral-500 b-y-solid p-3 pr-0 divide-x">
            {table.getHeaderGroups().map(headerGroup => (
              <div className="flex flex-1 items-center justify-center" key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <div
                      className={cn(
                        'flex items-center text-left uppercase typo-text-xs-em',
                        header.column.columnDef.header
                          ? 'justify-start flex-1'
                          : 'justify-end  hidden',
                      )}
                      key={header.id}
                    >
                      {header.isPlaceholder ||
                        flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div>
            {table.getRowModel().rows.map(row => {
              return (
                <div
                  className={cn(
                    'flex items-center border-1 border-neutral-300 border-b-solid py-3 pl-3',
                    selectedRows.includes(row.original.id) && 'bg-neutral-200',
                    'hover:bg-neutral-50',
                  )}
                  key={row.id}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <div
                        className={
                          cell.column.id === 'checkbox'
                            ? 'hidden'
                            : 'flex flex-1 items-center justify-center'
                        }
                        key={cell.id}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      </ReportTableResultsDisplay>
    </div>
  );
};
