import { ReportTable } from './ReportTable';
import { ReportPageActions } from './ReportPageActions';

export interface FilterProps {
  enabledFilterMenu: boolean;
  setEnabledFilterMenu: (value: boolean) => void;
}

export const ReportPage = () => {
  return (
    <div className="h-full w-full flex flex-col overflow-auto bg-neutral-100 p-x-6">
      <ReportPageActions />
      <ReportTable />
    </div>
  );
};
