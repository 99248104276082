export type LayerFilterKey = 'has2D' | 'has3D';

export type LayerFilterCategory = 'Annotations';

export const FilterOptions: Record<LayerFilterCategory, [LayerFilterKey, string][]> = {
  Annotations: [
    ['has2D', '2D Annotation'],
    ['has3D', '3D Annotation'],
  ],
};
