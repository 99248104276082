/**
 * Check if a search key matches an item.
 *
 * @param item
 * @param key
 * @returns
 */
export const search = (item: string, key: string) => {
  return item.toLowerCase().indexOf(key.trim().toLowerCase()) !== -1;
};
