import {
  BIMIcon,
  DXFIcon,
  IFCIcon,
  ImageIcon,
  Mesh3DIcon,
  OBJIcon,
  PointCloudIcon,
  TerrainIcon,
} from '@/components/Icon';
import { LAYER_FORMAT_TYPE } from '@/constants/layer';
import { Layer } from '@/utils/entities';
import { cn } from '@/utils/classname';
import { CheckBox } from '@skand/ui';

export interface LayerNodeProps {
  layer: Layer;
  isChecked: boolean;
  handleChange: () => void;
}

const FORMAT_TYPE_ICONS = {
  [LAYER_FORMAT_TYPE.IFC]: <IFCIcon />,
  [LAYER_FORMAT_TYPE.OBJ]: <OBJIcon />,
  [LAYER_FORMAT_TYPE.POINT_CLOUD]: <PointCloudIcon />,
  [LAYER_FORMAT_TYPE.MESH_3D]: <Mesh3DIcon />,
  [LAYER_FORMAT_TYPE.ORTHO_2D]: <TerrainIcon />,
  [LAYER_FORMAT_TYPE.TERRAIN]: <TerrainIcon />,
  [LAYER_FORMAT_TYPE.IMAGES]: <ImageIcon />,
  [LAYER_FORMAT_TYPE.DXF]: <DXFIcon />,
  [LAYER_FORMAT_TYPE.BIM_CAD_MODEL]: <BIMIcon />,
};

export const LayerNode = ({ layer, isChecked, handleChange }: LayerNodeProps) => {
  return (
    <div className={cn('flex justify-between items-center py-1 z-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <CheckBox checked={isChecked} onChange={handleChange} />
        {FORMAT_TYPE_ICONS[layer.formatType] ?? <div />}
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{layer.name}</p>
      </div>
    </div>
  );
};
