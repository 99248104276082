import { useMemo } from 'react';
import { Template, TemplateField, TemplateFieldOption } from './Template';
import { TemplateFilterKey } from './TemplateFilterKey';
import { Checkbox } from '@/components/Checkbox';
import { cn } from '@/utils/classname';
import { setStoredTemplateFilterKeys, useLayout } from '@/stores/layout';

export interface OptionsFilterProps {
  template: Template;
  field: TemplateField;
  templateFilterKeys: TemplateFilterKey[];
  expandedFields: Set<TemplateField>;
  setExpandedFields: (field: Set<TemplateField>) => void;
}

export const OptionsFilter = ({
  template,
  field,
  templateFilterKeys,
  expandedFields,
  setExpandedFields,
}: OptionsFilterProps) => {
  const storedTemplateFilterKeys = useLayout(state => state.storedTemplateFilterKeys);

  const fieldFilterKeys = useMemo(
    () => templateFilterKeys.filter(key => key.type === 'select' && key.fieldId === field.id),
    [field.id, templateFilterKeys],
  );

  // Handle toggling template fields dropdown
  const handleExpandField = () => {
    if (expandedFields.has(field)) {
      expandedFields.delete(field);
      setExpandedFields(new Set(expandedFields));
    } else {
      expandedFields.add(field);
      setExpandedFields(new Set(expandedFields));
    }
  };

  // Handle toggling the entire field
  const handleToggleField = () => {
    // Clear the keyset for this template
    for (const key of fieldFilterKeys) {
      storedTemplateFilterKeys.delete(key);
    }

    // If no keys were set, add all of them
    if (fieldFilterKeys.length === 0) {
      for (const option of field.options) {
        storedTemplateFilterKeys.add({
          type: 'select',
          templateId: template.id,
          fieldId: field.id,
          optionId: option.id,
        });
      }
    }
    setStoredTemplateFilterKeys(new Set(storedTemplateFilterKeys));
  };

  // Handle toggling template field value filter
  const handleToggleOption = (option: TemplateFieldOption) => {
    for (const field of fieldFilterKeys) {
      storedTemplateFilterKeys.delete(field);
    }

    const keyIndex = fieldFilterKeys.findIndex(
      key => key.type === 'select' && key.optionId === option.id,
    );
    if (keyIndex !== -1) {
      fieldFilterKeys.splice(keyIndex, 1);
    } else {
      fieldFilterKeys.push({
        type: 'select',
        templateId: template.id,
        fieldId: field.id,
        optionId: option.id,
      });
    }

    for (const field of fieldFilterKeys) {
      storedTemplateFilterKeys.add(field);
    }

    setStoredTemplateFilterKeys(new Set(storedTemplateFilterKeys));
  };

  // Check if the entire field is selected
  const [checked, fullyChecked] = useMemo(() => {
    const allFilterKeys = new Set<string>();
    for (const option of field.options) {
      allFilterKeys.add(field.id + option.id);
    }

    const setFilterKeys = new Set<string>();
    for (const key of fieldFilterKeys) {
      if (key.type === 'select') {
        setFilterKeys.add(key.fieldId + key.optionId);
      }
    }

    return [setFilterKeys.size > 0, setFilterKeys.size === allFilterKeys.size];
  }, [field.id, field.options, fieldFilterKeys]);

  return (
    <div className={cn('flex flex-col gap-2 items-start justify-center')} key={field.id}>
      <div className={cn('flex flex-row items-center justify-start gap-3')}>
        <div
          className={cn(
            'color-neutral-400 w-3 h-3',
            'cursor-pointer',
            expandedFields.has(field) ? 'i-skand-dropdown ' : 'i-skand-dropdown rotate-180',
          )}
          onClick={handleExpandField}
        />
        <Checkbox
          checked={checked}
          checkedPartial={checked && !fullyChecked}
          setToggleCheckbox={handleToggleField}
          small
        />
        <p className={cn('color-neutral-800', 'typo-text-s-em')}>{field.name}</p>
      </div>
      <div className="flex flex-col gap-2">
        {expandedFields.has(field) &&
          field.options.map(option => (
            <div
              className={cn('flex flex-row items-center justify-start gap-2', 'ml-10')}
              key={option.id}
            >
              <Checkbox
                checked={
                  !!fieldFilterKeys.find(
                    key =>
                      key.type === 'select' &&
                      key.optionId === option.id &&
                      key.fieldId === field.id,
                  )
                }
                setToggleCheckbox={() => handleToggleOption(option)}
                small
              />
              <p
                className={cn(
                  option.id === null ? 'color-neutral-400' : 'color-neutral-800',
                  'typo-text-s',
                )}
              >
                {option.value}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};
