import { CreateReportSort, switchCreateReportSort, useLayout } from '@/stores/layout';
import { cn } from '@/utils/classname';

const SORT_MAP = {
  name: 'name',
  createdAt: 'created date',
  updatedAt: 'last updated',
} as const;

export const Sort = () => {
  const sort = useLayout(state => state.createReportSort);

  return (
    <button
      className={cn(
        'flex items-center b-none bg-transparent uppercase color-neutral-800 typo-button-xs',
        'group/sort relative',
      )}
    >
      Sort by: {SORT_MAP[sort]}
      <div className="i-skand-dropdown ml-2" />
      <ul
        className={cn(
          'absolute right-0 top-full z-1',
          'my-1 list-none b-1 b-neutral-400 rounded-1 b-solid bg-neutral-100 p-1',
          'invisible opacity-0',
          'group-focus-within/sort:visible group-focus-within/sort:opacity-100',
        )}
      >
        {Object.entries(SORT_MAP).map(([key, value]) => {
          return (
            <li
              className={cn(
                'cursor-pointer',
                'whitespace-nowrap rounded-2px',
                'px-2 py-2px',
                'text-left decoration-none typo-button-xs',
                'hover:bg-neutral-200',
                key === sort ? 'color-neutral-800' : 'color-neutral-600',
              )}
              key={key}
              onMouseDown={() => switchCreateReportSort(key as CreateReportSort)}
            >
              {value}
            </li>
          );
        })}
      </ul>
    </button>
  );
};
