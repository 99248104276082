import { GRAPHQL_URL } from '@/constants/env';
import { getAuth } from '@/utils/auth';
import { GraphQLClient } from 'graphql-request';

const client = new GraphQLClient(GRAPHQL_URL, {
  headers: () => {
    return {
      Authorization: getAuth() || '',
    };
  },
  method: 'POST',
});

export const request = client.request.bind(client);
