import { AssetFile } from '@/graphql/codegen/graphql';
import { useReport } from '@/hooks/useReport';
import { useParams } from 'react-router-dom';

export const ReportViewPage = () => {
  const { id } = useParams<{ id: string }>();
  const { report } = useReport(id);

  if (!report) return <div className="typo-text-m-em">Loading...</div>;
  if (report.isPdfGenerating)
    return <div className="typo-text-m-em">Report is still generating</div>;
  if (!report.pdfFile) return <div className="typo-text-m-em">Report is not generated</div>;

  return (
    <object
      className="m-0 h-100% w-100%"
      data={(report.pdfFile as AssetFile).signedGetObjectUrl ?? ''}
      type="application/pdf"
    >
      <p className="typo-text-m-em">
        Unable to display PDF file.{' '}
        <a href={(report.pdfFile as AssetFile).signedGetObjectDownloadUrl ?? ''}>Download</a>{' '}
        instead.
      </p>
    </object>
  );
};
