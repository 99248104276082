import { Annotation } from '@/utils/entities';
import { cn } from '@/utils/classname';
import { CheckBox } from '@skand/ui';
import { HandleAnnotationNodeChange } from '../useTreeNodeActions';

export interface AnnotationNodeProps {
  annotation: Annotation;
  isChecked: boolean;
  handleChange: HandleAnnotationNodeChange;
}

export const AnnotationNode = ({ annotation, isChecked, handleChange }: AnnotationNodeProps) => {
  return (
    <div className={cn('flex justify-between items-center py-1 z-1')}>
      <div className={cn('flex items-center text-neutral-600')}>
        <CheckBox checked={isChecked} onChange={e => handleChange(e, annotation.id)} />
        <div className="flex flex-row gap-2 px-2">
          {annotation.has2D && <div className={'i-skand-annotation2doutlined'} />}
          {annotation.has3D && <div className={'i-skand-annotation3doutlined'} />}
        </div>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>
          {annotation.name}
        </p>
      </div>
    </div>
  );
};
