import { LIST_REPORTS_V2_BY_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';

export const queryKey = ['LIST_REPORTS_V2_BY_ACCOUNT_CONTEXT'];
export const getQueryKey = () => queryKey;

export const useReports = () => {
  const response = useQuery({
    queryFn: () => request(LIST_REPORTS_V2_BY_ACCOUNT_CONTEXT),
    queryKey,
    refetchInterval: 1000 * 30,
  });

  return response;
};
