import { useMutation } from '@tanstack/react-query';
import { request } from '@/graphql/request';
import { GENERATE_REPORT_V2_PDF } from '@/graphql/mutations';
import { queryClient } from '@/graphql/client';
import { toast } from '@skand/ui';

export const useGenerateReportPdf = (reportId: string) => {
  const generateReportPdf = useMutation({
    mutationFn: () => {
      return request(GENERATE_REPORT_V2_PDF, { reportId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['LIST_REPORTS_V2_BY_ACCOUNT_CONTEXT']);

      toast({
        type: 'info',
        message: (dismiss: () => void) => {
          return (
            <>
              Success! Your report is generating.{' '}
              <a className="cursor-pointer underline typo-link-xs" onClick={dismiss}>
                Dismiss
              </a>
            </>
          );
        },
      });
    },
    onError(e) {
      console.error('error', e);
      toast({
        type: 'warning',
        message: (dismiss: () => void) => {
          return (
            <>
              Failed to generate a new report PDF. Please try again later.{' '}
              <a className="cursor-pointer underline typo-link-xs" onClick={dismiss}>
                Dismiss
              </a>
            </>
          );
        },
      });
    },
  });

  return generateReportPdf;
};
