import { USERS_BY_ACCOUNTID_WITH_DEACTIVATED } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';

export const useUsers = () => {
  const response = useQuery({
    queryFn: () => request(USERS_BY_ACCOUNTID_WITH_DEACTIVATED),
    queryKey: ['USERS_BY_ACCOUNTID_WITH_DEACTIVATED'],
  });

  return response;
};
