import { FindIcon } from '@/components/Icon';
import { setStoredTemplateFilterKeys, useLayout } from '@/stores/layout';
import { cn } from '@/utils/classname';
import { search } from '@/utils/search';
import { Button, Input } from '@skand/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionsFilter } from './OptionsFilter';
import { Template, TemplateField } from './Template';
import { TemplateFilterKey } from './TemplateFilterKey';

export interface FieldsFilterMenuProps {
  template: Template;
}

export const FieldsFilterMenu = ({ template }: FieldsFilterMenuProps) => {
  const storedTemplateFilterKeys = useLayout(state => state.storedTemplateFilterKeys);

  const templateFilterKeys = useMemo(() => {
    const result: TemplateFilterKey[] = [];
    for (const key of storedTemplateFilterKeys) {
      if (key.templateId === template.id) {
        result.push(key);
      }
    }
    return result;
  }, [storedTemplateFilterKeys, template.id]);

  const [expandedFields, setExpandedFields] = useState<Set<TemplateField>>(new Set());
  const [searchKey, setSearchKey] = useState('');

  const filtered = useMemo(
    () =>
      template.fields.filter(field => {
        const fieldMatch = search(field.name, searchKey);
        const optionMatch = field.options.some(option => search(option.value, searchKey));
        return fieldMatch || optionMatch;
      }),
    [searchKey, template.fields],
  );

  // Clear the filters for the template
  const clearSelection = () => {
    for (const key of templateFilterKeys) {
      storedTemplateFilterKeys.delete(key);
    }
    setStoredTemplateFilterKeys(new Set(storedTemplateFilterKeys));
  };

  const expandAll = useCallback(() => {
    setExpandedFields(new Set(template.fields));
  }, [template.fields]);

  useEffect(() => {
    expandAll();
  }, [expandAll]);

  return (
    <div
      className={cn(
        'absolute',
        'w-full',
        'h-full',
        'top-0',
        'left-100%',
        'border-neutral-400',
        'border-1',
        'border-solid',
        'rounded-lg',
        'bg-neutral-100',
        'p-3',
        'flex flex-col',
      )}
    >
      <div className="w-full">
        <Input
          data-testid="input"
          label="Search"
          onChange={setSearchKey}
          tail={<FindIcon />}
          value={searchKey}
        />
      </div>
      <div className="flex justify-end gap-2">
        <div
          className={cn('typo-text-xs', 'color-neutral-800', 'mt-3 mb-3', 'cursor-pointer')}
          onClick={() => setExpandedFields(new Set(template.fields))}
        >
          <p>EXPAND ALL</p>
        </div>
        <div
          className={cn('typo-text-xs', 'color-neutral-800', 'mt-3 mb-3', 'cursor-pointer')}
          onClick={expandAll}
        >
          <p>COLLAPSE ALL</p>
        </div>
      </div>
      <div className="h-full flex flex-col gap-2 overflow-y-auto">
        {filtered.map(field => (
          <OptionsFilter
            expandedFields={expandedFields}
            field={field}
            key={field.id}
            setExpandedFields={setExpandedFields}
            template={template}
            templateFilterKeys={templateFilterKeys}
          />
        ))}
      </div>
      <div
        className={cn(
          'flex',
          'items-center justify-between gap-2',
          'mt-2',
          ' bottom-0',
          'sticky',
          'bg-white',
        )}
      >
        <Button
          className={cn('w-full')}
          disabled={templateFilterKeys.length === 0}
          onClick={clearSelection}
          size="s"
        >
          Clear selection
        </Button>
      </div>
    </div>
  );
};
