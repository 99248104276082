import { PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { useCallback, useMemo } from 'react';
import { useProjects } from './useProjects';
import { useUser } from './useUser';
import { useUserPermissionPolicies } from './useUserPermissionPolicies';
import { useIsSuperAdmin } from './useIsSuperAdmin';

export const useProjectsWithPermissions = (actionTypes: PolicyActionTypeInput[]) => {
  const projects = useProjects().data?.listProjectsByAccountContext;
  const { user } = useUser();
  const projectIds = useMemo(
    () =>
      projects?.reduce((prev, curr) => (curr?.id ? [...prev, curr.id] : prev), [] as string[]) ??
      [],
    [projects],
  );
  const projectGroupIds = useMemo(() => {
    return (
      projects?.reduce(
        (prev, curr) => (curr?.projectGroupId ? [...prev, curr.projectGroupId] : prev),
        [] as string[],
      ) ?? []
    );
  }, [projects]);

  const isSuperAdmin = useIsSuperAdmin();

  const { policies } = useUserPermissionPolicies({
    actionTypes,
    userId: user?.id ?? null,
    objectIds: [...projectIds, ...projectGroupIds],
    objectTypes: [PolicyObjectTypeInput.Project, PolicyObjectTypeInput.ProjectGroup],
  });

  const hasPermissionToAllResources = useMemo(() => {
    return policies.some(
      policy =>
        policy.objectId === '*' &&
        (policy.objectType === '*' || policy.objectType === PolicyObjectTypeInput.All),
    );
  }, [policies]);

  const hasPermissionToAllProjects = useMemo(() => {
    return policies.some(
      policy => policy.objectId === '*' && policy.objectType === PolicyObjectTypeInput.Project,
    );
  }, [policies]);

  const hasPermissionToAllProjectGroups = useMemo(() => {
    return policies.some(
      policy => policy.objectId === '*' && policy.objectType === PolicyObjectTypeInput.ProjectGroup,
    );
  }, [policies]);

  const checkPermissions = useCallback(
    (projectId: string, projectGroupId: null | string) => {
      if (isSuperAdmin) return true;
      if (hasPermissionToAllResources) return true;
      if (hasPermissionToAllProjects) return true;
      if (projectGroupId && hasPermissionToAllProjectGroups) return true;

      for (const policy of policies) {
        if (policy.objectId === projectId && policy.objectType === PolicyObjectTypeInput.Project)
          return true;

        if (
          projectGroupId &&
          policy.objectId === projectGroupId &&
          policy.objectType === PolicyObjectTypeInput.ProjectGroup
        )
          return true;
      }

      return false;
    },
    [
      hasPermissionToAllProjectGroups,
      hasPermissionToAllProjects,
      hasPermissionToAllResources,
      policies,
      isSuperAdmin,
    ],
  );

  const projectsWithPermissions = useMemo(() => {
    return projects?.filter(
      project => project?.id && checkPermissions(project.id, project?.projectGroupId ?? null),
    );
  }, [checkPermissions, projects]);

  const checkPermissionsByProjectId = useCallback(
    (projectId: string) => {
      const projectGroupId =
        projects?.find(project => project?.id === projectId)?.projectGroupId ?? null;

      return checkPermissions(projectId, projectGroupId);
    },
    [checkPermissions, projects],
  );

  return {
    checkPermissions,
    checkPermissionsByProjectId,
    hasPermissionToAllProjectGroups,
    hasPermissionToAllProjects,
    hasPermissionToAllResources,
    projectsWithPermissions,
  };
};
