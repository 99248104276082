import { LIST_PROJECTS_BY_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';

export const useProjects = () => {
  const response = useQuery({
    queryFn: () => request(LIST_PROJECTS_BY_ACCOUNT_CONTEXT),
    queryKey: ['LIST_PROJECTS_BY_ACCOUNT_CONTEXT'],
  });

  return response;
};
