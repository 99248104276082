import { SceneEntity } from '@/graphql/codegen/graphql';
import {
  LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES,
  READ_REPORT_V2_BY_ID_WITH_PAYLOAD,
} from '@/graphql/queries';
import { request } from '@/graphql/request';
import { DocumentNode } from 'graphql';

interface ReportResult {
  readReportV2ByIdWithPayload: {
    projectId: string;
  };
}

const getQueryKey = (id: string) => [
  'READ_REPORT_V2_BY_ID_WITH_PAYLOAD',
  'LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES',
  id,
];

export const useReportWithPayload = (id: null | string) => {
  return async () => {
    const reportResult = (await request(READ_REPORT_V2_BY_ID_WITH_PAYLOAD as DocumentNode, {
      reportId: id as string,
    })) as ReportResult;
    const sceneEntitiesResult = await request(
      LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES,
      {
        projectId: reportResult?.readReportV2ByIdWithPayload?.projectId as string,
        rendererTypes: [],
      },
    );

    const report = reportResult?.readReportV2ByIdWithPayload;
    const sceneEntities = sceneEntitiesResult?.listSceneEntitiesByProjectIdAndRendererTypes;

    if (report && sceneEntities) {
      const sceneEntitiesFromChildToRootArrays: SceneEntity[][] = [];

      const findParentsToRoot = (sceneEntity: SceneEntity) => {
        const parentSceneEntityId = (sceneEntity as SceneEntity).parentSceneEntityId;
        if (parentSceneEntityId) {
          const parentSceneEntity = sceneEntities.find(
            (sceneEntity: SceneEntity | null) => sceneEntity?.id === parentSceneEntityId,
          );
          if (parentSceneEntity) {
            sceneEntitiesFromChildToRootArrays.push([
              sceneEntity as SceneEntity,
              parentSceneEntity as SceneEntity,
            ]);
            findParentsToRoot(parentSceneEntity);
          }
        } else {
          sceneEntitiesFromChildToRootArrays.push([sceneEntity as SceneEntity]);
        }
      };

      sceneEntities.forEach((sceneEntity: SceneEntity | null) => {
        findParentsToRoot(sceneEntity as SceneEntity);
      });

      return { report, sceneEntities, sceneEntitiesFromChildToRootArrays };
    }
  };
};

useReportWithPayload.getQueryKey = getQueryKey;
