import { FilterOptions, LayerFilterCategory } from './LayerFilterKey';
import { LayerFilterGroup } from './LayerFilterGroup';
import { cn } from '@/utils/classname';

export const LayerFilterTab = () => {
  return (
    <div className={cn('flex', 'flex-col', 'justify-start', 'm-3')}>
      <div className={cn('mb-1')}>
        <p className={cn('typo-text-s-em', 'color-neutral-800')}>Layer types</p>
      </div>
      <div className={cn('flex', 'flex-col', 'justify-start')}>
        {Object.keys(FilterOptions).map(key => (
          <LayerFilterGroup category={key as LayerFilterCategory} key={key} />
        ))}
      </div>
    </div>
  );
};
