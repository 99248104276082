export const Mesh3DIcon = () => {
  return (
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.857178 3.52936V8.7032L5.48563 11.148V6.25842L0.857178 3.52936Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M5.48584 11.148L11.1428 8.13462V3.24506L5.48584 6.25839"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M11.1426 3.24514L6.32132 0.800354L0.857178 3.52941"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
