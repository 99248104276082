export const BIMIcon = () => {
  return (
    <svg fill="none" height="12" viewBox="0 0 19 12" width="19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.72266 6.46484H3.26953L3.26172 5.70703H4.50781C4.72135 5.70703 4.89974 5.67708 5.04297 5.61719C5.1862 5.55729 5.29427 5.47005 5.36719 5.35547C5.44271 5.24089 5.48047 5.10156 5.48047 4.9375C5.48047 4.75521 5.44531 4.60677 5.375 4.49219C5.30729 4.3776 5.20052 4.29427 5.05469 4.24219C4.90885 4.1875 4.72396 4.16016 4.5 4.16016H3.61719V9H2.54297V3.3125H4.5C4.82292 3.3125 5.11068 3.34375 5.36328 3.40625C5.61849 3.46615 5.83464 3.5599 6.01172 3.6875C6.1888 3.8151 6.32292 3.97526 6.41406 4.16797C6.50781 4.36068 6.55469 4.58984 6.55469 4.85547C6.55469 5.08984 6.5 5.30599 6.39062 5.50391C6.28385 5.69922 6.11849 5.85807 5.89453 5.98047C5.67318 6.10286 5.39193 6.17318 5.05078 6.19141L4.72266 6.46484ZM4.67578 9H2.95312L3.40234 8.15625H4.67578C4.88932 8.15625 5.0651 8.12109 5.20312 8.05078C5.34375 7.98047 5.44792 7.88411 5.51562 7.76172C5.58594 7.63672 5.62109 7.49349 5.62109 7.33203C5.62109 7.15495 5.58984 7.0013 5.52734 6.87109C5.46745 6.74089 5.37109 6.64062 5.23828 6.57031C5.10807 6.5 4.9362 6.46484 4.72266 6.46484H3.60547L3.61328 5.70703H5.03516L5.28125 6C5.60938 6.0026 5.8763 6.06771 6.08203 6.19531C6.29036 6.32292 6.44401 6.48698 6.54297 6.6875C6.64193 6.88802 6.69141 7.10417 6.69141 7.33594C6.69141 7.70052 6.61198 8.00651 6.45312 8.25391C6.29688 8.5013 6.06771 8.6875 5.76562 8.8125C5.46615 8.9375 5.10286 9 4.67578 9ZM8.76562 3.3125V9H7.69141V3.3125H8.76562ZM10.3945 3.3125H11.3242L12.8789 7.59375L14.4297 3.3125H15.3633L13.25 9H12.5039L10.3945 3.3125ZM9.92578 3.3125H10.8359L11 7.24609V9H9.92578V3.3125ZM14.918 3.3125H15.832V9H14.7578V7.24609L14.918 3.3125Z"
        fill="currentColor"
      />
      <rect height="11" rx="1.5" stroke="currentColor" width="18" x="0.5" y="0.5" />
    </svg>
  );
};
