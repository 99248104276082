import { FindIcon } from '@/components/Icon';
import { PolicyActionTypeInput } from '@/graphql/codegen/graphql';
import { useProjectsWithPermissions } from '@/hooks/useProjectsWithPermissions';
import { Input, Select } from '@skand/ui';
import { useMemo } from 'react';

interface SelectProjectInputProps {
  projectId: string | null;
  projectFilter: string;
  selectProject: (projectId: string) => void;
  setProjectFilter: (projectFilter: string) => void;
}

export const SelectProjectInput = ({
  projectId,
  projectFilter,
  selectProject,
  setProjectFilter,
}: SelectProjectInputProps) => {
  const { projectsWithPermissions } = useProjectsWithPermissions([
    PolicyActionTypeInput.Admin,
    PolicyActionTypeInput.Edit,
  ]);

  const projectsOptions = useMemo(() => {
    const options = [];

    for (const project of projectsWithPermissions ?? []) {
      if (!project) continue;
      const isIncluded = projectFilter
        ? (project.name ?? '').toLocaleLowerCase().includes(projectFilter.toLocaleLowerCase())
        : true;
      if (!isIncluded) continue;

      options.push({
        key: project.id ?? '',
        name: project.name ?? '',
      });
    }

    return options;
  }, [projectFilter, projectsWithPermissions]);

  return (
    <Select
      className="h-full w-full"
      filterSlot={
        <div className="mb-1">
          <Input
            onChange={setProjectFilter}
            placeholder="Search"
            style={{ width: '100%' }}
            tail={<FindIcon />}
            value={projectFilter}
          />
        </div>
      }
      label="Select project"
      onChange={selectProject}
      options={projectsOptions}
      value={projectId}
      width="full"
    />
  );
};
