import { queryClient } from '@/graphql/client';
import { DELETE_REPORT_V2 } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useGenerateReportPdf } from '@/hooks/useGenerateReportPdf';
import { cn } from '@/utils/classname';
import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Button, Menu, MenuItem, Modal, toast } from '@skand/ui';

import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

interface MoreProps {
  rowId: string;
  isPdfGenerating: boolean;
  fileName: string;
  hasEditAccess: boolean;
}

export const More = ({ rowId, isPdfGenerating, fileName, hasEditAccess }: MoreProps) => {
  const [open, setOpen] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom-end',
  });
  const click = useClick(context);
  const dimiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dimiss]);
  const { mutate: generateReportPdf } = useGenerateReportPdf(rowId);

  const handleGenerate = useCallback(() => {
    generateReportPdf();
    setOpen(false);
  }, [generateReportPdf]);

  const deleteReport = useMutation({
    mutationFn: () => {
      return request(DELETE_REPORT_V2, { reportId: rowId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['LIST_REPORTS_V2_BY_ACCOUNT_CONTEXT']);

      toast({
        type: 'info',
        message: (dismiss: () => void) => {
          return (
            <>
              Success! Your report is deleted.{' '}
              <a className="underline typo-link-xs" onClick={dismiss}>
                Dismiss
              </a>
            </>
          );
        },
      });

      setOpen(false);
    },
    onError() {
      toast({
        type: 'warning',
        message: (dismiss: () => void) => {
          return (
            <>
              Failed to delete the report. Please try again later.{' '}
              <a className="color-neutral-800 underline typo-link-xs" onClick={dismiss}>
                Dismiss
              </a>
            </>
          );
        },
      });
    },
  });

  const handleDelete = useCallback(() => {
    deleteReport.mutate();
    setOpenConfirmationModal(false);
  }, [deleteReport]);

  return (
    <>
      {openConfirmationModal && (
        <Modal>
          <div className="mt-1 flex flex-col gap-2">
            <p className="color-neutral-800 typo-text-m">Delete report</p>
            <p className="color-neutral-800 typo-text-s">
              This action cannot be undone. Are you sure you want to delete the report <br />
              <span className="color-grey-500 typo-text-s-em">{fileName}</span>?
            </p>
          </div>
          <div className="w-full flex gap-2">
            <Button
              className="w-full cursor-pointer"
              onClick={() => {
                setOpenConfirmationModal(false);
              }}
              size="s"
            >
              Cancel
            </Button>
            <Button
              className="w-full cursor-pointer"
              filled
              onClick={() => {
                handleDelete();
              }}
              primary
              size="s"
            >
              Delete
            </Button>
          </div>
        </Modal>
      )}
      <div
        className={cn(
          'i-skand-more cursor-pointer color-neutral-500',
          'hover:color-primary-400',
          open && 'color-primary-400',
        )}
        ref={refs.setReference}
        {...getReferenceProps()}
      />

      {open && (
        <Menu className="z-1" ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          <MenuItem className="cursor-pointer" disabled={isPdfGenerating} onClick={handleGenerate}>
            Regenerate
          </MenuItem>
          <MenuItem
            className="cursor-pointer"
            disabled={!hasEditAccess}
            onClick={() => setOpenConfirmationModal(true)}
          >
            Delete
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
