import { useMemo, useState } from 'react';
import { cn } from '@/utils/classname';
import { Template, TemplateField, TemplateFieldOption } from './Template';
import { useLayout } from '@/stores/layout';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { AnnotationTemplate, AnnotationTemplateSelectField } from '@/graphql/codegen/graphql';
import { TemplateRow } from './TemplateRow';
import { FieldsFilterMenu } from './FieldsFilterMenu';

export const TemplateFilterTab = () => {
  const projectId = useLayout(state => state.projectId);
  const templatesResponse = useAnnotationTemplates(projectId);

  const templates = useMemo(() => {
    return (
      templatesResponse.data?.listAnnotationTemplatesByProjectId?.filter(
        (item): item is SolidId<AnnotationTemplate> => !!item?.id,
      ) || []
    );
  }, [templatesResponse.data?.listAnnotationTemplatesByProjectId]);

  // Generate list of templates with select fields
  const selectTemplates = useMemo(() => {
    const groups: Template[] = [];
    for (const template of templates) {
      const fields: TemplateField[] = [];
      for (const entry of template.fields ?? []) {
        if (entry?.type !== 'SELECT') continue;
        const field = entry as AnnotationTemplateSelectField;
        const options: TemplateFieldOption[] = [
          {
            id: null,
            value: 'Undefined',
          },
        ];
        for (const option of field.options ?? []) {
          options.push({
            id: option?.id as string,
            value: option?.value as string,
          });
        }
        fields.push({
          id: field.id as string,
          name: field.name as string,
          options,
        });
      }
      groups.push({
        id: template.id,
        name: template.name as string,
        fields,
      });
    }
    return groups;
  }, [templates]);

  const [activeTemplate, setActiveTemplate] = useState<Template | null>(null);

  return (
    <div className={cn('flex', 'flex-col', 'justify-start')}>
      <div className={cn('ml-3 mb-1')}>
        <p className={cn('typo-text-s-em', 'color-neutral-800')}>Templates & Fields</p>
      </div>
      <div className={cn('flex', 'flex-col', 'justify-start')}>
        {selectTemplates.map(template => (
          <TemplateRow
            activeTemplate={activeTemplate}
            key={template.id}
            setActiveTemplate={setActiveTemplate}
            template={template}
          />
        ))}
      </div>
      {activeTemplate && <FieldsFilterMenu template={activeTemplate} />}
    </div>
  );
};
