import { PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { isDefiend } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (
  actionTypes: PolicyActionTypeInput[],
  objectIds: null | string[],
  objectTypes: PolicyObjectTypeInput[],
  userId: null | string,
) => [
  'LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_GROUPING_POLICIES_PERMISSIONS',
  actionTypes,
  objectIds,
  objectTypes,
  userId,
];

export const useUserPermissionPolicies = ({
  actionTypes,
  objectIds,
  objectTypes,
  userId,
}: {
  actionTypes: PolicyActionTypeInput[];
  objectIds: null | string[];
  objectTypes: PolicyObjectTypeInput[];
  userId: null | string;
}) => {
  const query = useQuery({
    enabled: !!userId,
    queryFn: () =>
      request(LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS, {
        actionTypes: actionTypes,
        objectIds: ['*', ...(objectIds ?? [])],
        objectTypes: [PolicyObjectTypeInput.All, ...objectTypes],
        subjectId: userId as string,
      }),
    queryKey: getQueryKey(actionTypes, objectIds, objectTypes, userId),
  });

  const policies = useMemo(() => {
    return (
      query.data?.listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds?.filter(isDefiend) ??
      []
    );
  }, [query.data?.listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds]);

  return { policies, query };
};

useUserPermissionPolicies.getQueryKey = getQueryKey;
