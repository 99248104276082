import { TemplateFilterKey } from '@/components/FilterMenu';
import { deselectAnnotations, selectAnnotations, useLayout } from '@/stores/layout';
import { Annotation, AnnotationGroup, Layer, LayerGroup, SceneEntity } from '@/utils/entities';
import { search } from '@/utils/search';
import { CheckBox, Tree, TreeNodeProps, Spinner } from '@skand/ui';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { ListSelected } from './ListSelected';
import { Sort } from './Sort';
import { TreeNode } from './TreeNode';
import {
  HandleAnnotationNodeChange,
  HandleNodeChange,
  IsNodeChecked,
  useTreeNodeActions,
} from './useTreeNodeActions';

export interface TreeViewProps {
  layerGroups: LayerGroup[];
  layers: Layer[];
  annotationGroups: AnnotationGroup[];
  isLoading: boolean;
}

export const TreeView = ({ layerGroups, layers, annotationGroups, isLoading }: TreeViewProps) => {
  const selectedAnnotationIds = useLayout(state => state.annotationIds);
  const createReportCondition = useLayout(state => state.createReportCondition);
  const createReportSort = useLayout(state => state.createReportSort);
  const templateFilterKeys = useLayout(state => state.storedTemplateFilterKeys);
  const layerFilterKeys = useLayout(state => state.storedLayerFilterKeys);

  const [isNodeChecked, handleNodeChange, handleAnnotationNodeChange] = useTreeNodeActions();

  // List of all annotations
  const annotations = useMemo(
    () => annotationGroups.flatMap(group => group.annotations),
    [annotationGroups],
  );

  const { annotationIds } = useLayout(state => state);

  const annotationIdsObject = useMemo(
    () => Object.fromEntries(annotationIds.map(id => [id, true])),
    [annotationIds],
  );

  // Filter an annotation by its template fields
  const filterAnnotationFields = useCallback(
    (annotation: Annotation) => {
      // If no template filters are set, pass all annotations
      if (templateFilterKeys.size === 0) {
        return true;
      }

      // Check if the annotation template is included in the filter list
      const filterKeys: TemplateFilterKey[] = [];
      for (const key of templateFilterKeys) {
        if (key.templateId === annotation.template.id) {
          filterKeys.push(key);
        }
      }
      if (filterKeys.length === 0) {
        return false;
      }

      // Map of select fields to possible options
      const selectFilterEntries = new Map<string, (string | null)[]>();
      for (const key of templateFilterKeys) {
        if (key.templateId === annotation.template.id) {
          if (key.type === 'select') {
            const options = selectFilterEntries.get(key.fieldId) ?? [];
            options.push(key.optionId);
            selectFilterEntries.set(key.fieldId, options);
          }
        }
      }

      // Map of annotation field to option
      const selectFieldEntries = new Map<string, string>();
      for (const field of annotation.fields) {
        if (field.__typename !== 'AnnotationSelectField') continue;
        selectFieldEntries.set(field.fieldId as string, field.optionId as string);
      }

      // OR operation among options of a field
      // AND operation among fields of a template
      // OR operation among templates
      let pass = true;
      for (const [fieldId, optionIds] of selectFilterEntries) {
        const optionId = selectFieldEntries.get(fieldId) ?? null;
        if (!optionIds.includes(optionId)) {
          pass = false;
          break;
        }
      }
      return pass;
    },
    [templateFilterKeys],
  );

  // Filter an annotation by whether it has a 2D or 3D sketch
  const filterLayerKeys = useCallback(
    (annotation: Annotation) => {
      if (!layerFilterKeys.size) return true;
      const hasSketch2D = layerFilterKeys.has('has2D') && annotation.has2D;
      const hasSketch3D = layerFilterKeys.has('has3D') && annotation.has3D;
      return hasSketch2D || hasSketch3D;
    },
    [layerFilterKeys],
  );

  // Count the annotations under a node
  const countAnnotations = useCallback((node: SceneEntity) => {
    const queue = [node];
    let count = 0;
    while (queue.length) {
      const node = queue.shift();
      if (node) {
        queue.push(...node.children);
        if (node.entity.type === 'annotation') {
          count++;
        }
      }
    }
    return count;
  }, []);

  // Compute the scene entity tree
  const filteredTree = useMemo(() => {
    const entities = [...layerGroups, ...layers, ...annotationGroups];
    const nodeMap = new Map<(typeof entities)[number], SceneEntity>();
    for (const entity of entities) {
      const node: SceneEntity = { entity, children: [] };
      if (entity.type === 'annotationGroup') {
        for (const annotation of entity.annotations) {
          if (filterAnnotationFields(annotation) && filterLayerKeys(annotation)) {
            node.children.push({
              entity: annotation,
              children: [],
            });
          }
        }
      }
      nodeMap.set(entity, node);
    }

    const nodes: SceneEntity[] = [];
    for (const entity of entities) {
      const node = nodeMap.get(entity);
      if (node) {
        if (entity.parent) {
          const parent = nodeMap.get(entity.parent);
          if (parent) {
            parent.children.push(node);
          }
        } else {
          nodes.push(node);
        }
      }
    }

    // Cull root nodes with no annotation descendants
    for (let i = nodes.length - 1; i >= 0; i--) {
      const node = nodes[i];
      if (countAnnotations(node) === 0) {
        nodes.splice(i, 1);
      }
    }
    // Traverse tree and cull nodes with no annotation descendants
    const queue = [...nodes];
    while (queue.length) {
      const node = queue.shift();
      if (node) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          const child = node.children[i];
          if (countAnnotations(child) === 0) {
            node.children.splice(i, 1);
          }
        }
        queue.push(...node.children);
      }
    }

    return nodes;
  }, [
    layerGroups,
    layers,
    annotationGroups,
    filterAnnotationFields,
    filterLayerKeys,
    countAnnotations,
  ]);

  // Apply search result
  const tree = useMemo(() => {
    let results = [...filteredTree];

    const searchQuery = createReportCondition.search;
    if (searchQuery.length > 0) {
      results = [];
      const queue = [...filteredTree];
      while (queue.length && searchQuery.length) {
        const node = queue.shift();
        if (node) {
          queue.push(...node.children);
          if (search(node.entity.name, searchQuery)) {
            results.push(node);
          }
        }
      }
    }
    return results;
  }, [createReportCondition.search, filteredTree]);

  // Map of each node to its annotation descendants
  const annotationMap = useMemo(() => {
    const result = new Map<SceneEntity, Set<Annotation>>();

    // BFS to find all leaf nodes and record parent nodes
    const queue = [...tree];
    const parent = new Map<SceneEntity, SceneEntity>();
    const leaves: SceneEntity[] = [];
    while (queue.length) {
      const node = queue.shift() as SceneEntity;

      // Record leaf nodes
      if (node.children.length === 0) {
        leaves.push(node);
      }

      // Enqueue children
      for (const child of node.children) {
        parent.set(child, node);
        queue.push(child);
      }
    }

    // Walk up the tree to produce annotation map
    for (const leaf of leaves) {
      if (leaf.entity.type !== 'annotation') {
        continue;
      }

      let current: SceneEntity | undefined = leaf;
      while (current) {
        const set = result.get(current) ?? new Set();
        set.add(leaf.entity);
        result.set(current, set);

        current = parent.get(current);
      }
    }
    return result;
  }, [tree]);

  // Filtered annotations
  const filteredAnnotations = useMemo(() => {
    const nodes = Array.from(annotationMap.keys()).filter(
      node => node.entity.type === 'annotation',
    );
    return nodes.map(node => node.entity) as Annotation[];
  }, [annotationMap]);

  // Wrapper to pass the annotation map to TreeNode
  const TreeNodeWrapped = useCallback(
    (props: TreeNodeProps<SceneEntity>) => (
      <TreeNode
        {...props}
        annotationIdsObject={annotationIdsObject}
        annotationMap={annotationMap}
        handleAnnotationNodeChange={handleAnnotationNodeChange as HandleAnnotationNodeChange}
        handleNodeChange={handleNodeChange as HandleNodeChange}
        isNodeChecked={isNodeChecked as IsNodeChecked}
      />
    ),
    [
      annotationIdsObject,
      annotationMap,
      handleAnnotationNodeChange,
      handleNodeChange,
      isNodeChecked,
    ],
  );

  // Handle selecting all currently filtered annotations
  const handleChangeCheckBox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        selectAnnotations(filteredAnnotations.map(annotation => annotation.id));
      } else {
        deselectAnnotations(filteredAnnotations.map(annotation => annotation.id));
      }
    },
    [filteredAnnotations],
  );

  // Tree row sort comparison function
  const treeSortCmp = useCallback(
    (a: SceneEntity, b: SceneEntity) => {
      const typeScore = (node: SceneEntity) => {
        switch (node.entity.type) {
          case 'annotation':
            return 1;
          case 'annotationGroup':
            return 2;
          case 'layer':
            return 3;
          case 'layerGroup':
            return 4;
        }
      };

      const sizeCmp = a.children.length - b.children.length;
      const typeCmp = typeScore(a) - typeScore(b);
      let modeCmp = 0;
      if (createReportSort === 'name') {
        modeCmp = b.entity.name.localeCompare(a.entity.name);
      } else if (createReportSort === 'createdAt' || createReportSort === 'updatedAt') {
        if (a.entity.type === 'annotation' && b.entity.type === 'annotation') {
          modeCmp = a.entity.createdAt.getTime() - b.entity.createdAt.getTime();
        } else if (a.entity.type === 'annotationGroup' && b.entity.type === 'annotationGroup') {
          modeCmp = a.entity.createdAt.getTime() - b.entity.createdAt.getTime();
        }
      }
      return modeCmp || sizeCmp || typeCmp;
    },
    [createReportSort],
  );

  // Check if all filtered annotations are selected
  const isChecked = useMemo(() => {
    const set = new Set(selectedAnnotationIds);
    for (const annotation of filteredAnnotations) {
      if (set.has(annotation.id)) {
        return true;
      }
    }
    return false;
  }, [filteredAnnotations, selectedAnnotationIds]);

  return (
    <div className="overflow-none h-full flex flex-col px-3 pb-16">
      <div className="h-40%">
        <ListSelected annotations={annotations} />
      </div>
      <div className="flex flex-none items-center justify-between b-b-1 b-b-neutral-500 b-b-solid bg-neutral-100 pb-2 pt-2">
        <label className="flex items-center">
          <CheckBox checked={isChecked} onChange={handleChangeCheckBox} />
          <p className="ml-2 uppercase color-neutral-800 typo-button-xs">Annotation name</p>
        </label>
        <Sort />
      </div>
      {isLoading ? (
        <div className="flex flex-row gap-2 p-2">
          <Spinner className="h-6 w-6 animate-spin" />
          <p className="color-neutral-400 typo-text-s">Loading annotations</p>
        </div>
      ) : tree && tree.length > 0 ? (
        <div
          className="h-45% flex-1"
          style={{
            scrollbarGutter: 'stable',
          }}
        >
          <Tree
            getKey={node => node.entity.id}
            quickListProps={{ scrollbarSpace: 20 }}
            roots={tree}
            sortCmp={treeSortCmp}
            walker={node => node.children}
          >
            {TreeNodeWrapped}
          </Tree>
        </div>
      ) : tree.length === 0 ? (
        <div className="h-full flex flex-1 items-center justify-center">
          <p className="color-neutral-400 typo-text-m">No annotations to display</p>
        </div>
      ) : null}
    </div>
  );
};
