export const TerrainIcon = () => {
  return (
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
      <mask fill="white" id="path-1-inside-1_2852_56014">
        <rect height="12" rx="0.6" width="12" />
      </mask>
      <rect
        height="12"
        mask="url(#path-1-inside-1_2852_56014)"
        rx="0.6"
        stroke="currentColor"
        strokeWidth="2.016"
        width="12"
      />
      <line
        stroke="currentColor"
        strokeWidth="1.008"
        x1="0.600098"
        x2="11.4001"
        y1="4.29605"
        y2="4.29605"
      />
      <line
        stroke="currentColor"
        strokeWidth="1.008"
        x1="0.600098"
        x2="11.4001"
        y1="7.89602"
        y2="7.89602"
      />
      <line
        stroke="currentColor"
        strokeWidth="1.008"
        x1="4.29605"
        x2="4.29605"
        y1="11.4"
        y2="0.600024"
      />
      <line
        stroke="currentColor"
        strokeWidth="1.008"
        x1="7.8959"
        x2="7.8959"
        y1="11.4"
        y2="0.600024"
      />
    </svg>
  );
};
