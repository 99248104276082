export const FindIcon = () => {
  return (
    <svg
      className="ml-2"
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.27472 11.5494C9.18787 11.5494 11.5494 9.18787 11.5494 6.27472C11.5494 3.36157 9.18787 1 6.27472 1C3.36157 1 1 3.36157 1 6.27472C1 9.18787 3.36157 11.5494 6.27472 11.5494Z"
        stroke="#BCB9BE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M10.033 10.0332L13 13.0002"
        stroke="#BCB9BE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
