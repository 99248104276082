import { cn } from '@/utils/classname';
import { HTMLAttributes, forwardRef } from 'react';

export interface CellProps extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export const Cell = forwardRef<HTMLDivElement, CellProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        className={cn(
          'w-full',
          'grid grid-cols-1 ',
          'items-center justify-start ',
          'whitespace-nowrap border-1 text-sm color-neutral-800 typo-text-s',
          className,
        )}
        ref={ref}
        {...props}
      >
        <div className="col-span-1 overflow-hidden" title={children as string}>
          {children}
        </div>
      </div>
    );
  },
);

Cell.displayName = 'Cell';
