import { Annotation } from '@/utils/entities';
import { deselectAnnotation } from '@/stores/layout';
import { cn } from '@/utils/classname';
import { useCallback } from 'react';

export interface ListSelectedRowProps {
  annotation: Annotation;
}

export const ListSelectedRow = ({ annotation }: ListSelectedRowProps) => {
  const handleDelete = useCallback(() => deselectAnnotation(annotation.id), [annotation.id]);

  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>
          {annotation.name}
        </p>
        {annotation.has2D && <div className={'i-skand-annotation2doutlined'} />}
        {annotation.has3D && <div className={'i-skand-annotation3doutlined'} />}
      </div>
      <div
        className="i-skand-close cursor-pointer text-3 color-neutral-400"
        onClick={handleDelete}
      />
    </div>
  );
};
