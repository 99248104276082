import * as PATHS from '@/constants/paths';
import { client, queryClient } from '@/graphql/client';
import { ReportViewPage } from '@/pages/ReportViewPage';
import { history as historyRef } from '@/utils/history';
import { config } from '@/utils/split';
import { ApolloProvider } from '@apollo/client';
import { ToastRoot } from '@skand/ui';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { ReportPage } from '../pages/ReportPage';
import { PrivateRoute } from './PrivateRoute';

export const Root = () => {
  const history = useHistory();

  useEffect(() => {
    historyRef.current = history;
  }, [history]);

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <SplitFactory config={config}>
          <BrowserRouter>
            <Switch>
              <PrivateRoute fallback={PATHS.ROOT}>
                <Route exact path={PATHS.REPORTS_VIEW}>
                  <ReportViewPage />
                </Route>
                <Route exact path={PATHS.REPORTS_ROOT}>
                  <ReportPage />
                </Route>
              </PrivateRoute>
            </Switch>
            <ToastRoot className="z-1" size="l" />
          </BrowserRouter>
        </SplitFactory>
      </QueryClientProvider>
    </ApolloProvider>
  );
};
