import React from 'react';
import { ReportsDisplayState } from './ReportTable';
import { Skeleton } from '@skand/ui';
import { EmptyResourceMessage } from '@/components/EmptyResourceMessage';
import { Repeat } from '@/components/Repeat';

interface ReportTableResultsDisplayProps {
  children: React.ReactNode;
  resultsDisplayState: ReportsDisplayState;
  openCreateReport: () => void;
  columnLength: number;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
}

export const ReportTableResultsDisplay = ({
  children,
  resultsDisplayState,
  openCreateReport,
  columnLength,
  searchQuery,
  setSearchQuery,
}: ReportTableResultsDisplayProps) => {
  const stateMap = {
    [ReportsDisplayState.Loading]: (
      <>
        <div className="mt-6 flex items-center gap-3 b-1 b-neutral-500 b-y-solid py-3 pr-0 divide-x">
          <Skeleton className="h-14px w-full" />
        </div>
        <div>
          <Repeat n={24}>
            <div className="">
              <div className="mt-12px flex gap-3">
                <Repeat n={columnLength}>
                  <Skeleton className="h-28px w-full" />
                </Repeat>
              </div>
            </div>
          </Repeat>
        </div>
      </>
    ),
    [ReportsDisplayState.ReportsEmpty]: (
      <div className="my-3 h-full b-neutral-200 rounded-2 b-solid">
        <EmptyResourceMessage
          emptyResourceContent={{
            actionButton: openCreateReport,
            emptyDesc: 'use the Create Report button to generate a new report.',
            emptyTitle: 'No reports available',
            buttonLabel: 'Create report',
            emptyToast: 'Check with your Admin for your permissions if content is expected here',
          }}
        />
      </div>
    ),
    [ReportsDisplayState.EmptyListAndSearchResults]: (
      <div className="my-3 h-full b-neutral-200 rounded-2 b-solid">
        <EmptyResourceMessage
          emptyResourceContent={{
            actionButton: openCreateReport,
            emptyDesc: 'use the Create Report button to generate a new report.',
            emptyTitle: 'No reports available to search',
            buttonLabel: 'Create report',
            emptyToast: 'Check with your Admin for your permissions if content is expected here',
          }}
        />
      </div>
    ),
    [ReportsDisplayState.SearchResultsEmpty]: (
      <EmptyResourceMessage
        emptyResourceContent={{
          actionButton: () => {
            setSearchQuery('');
          },
          emptyDesc: (
            <div className="max-w-384px">
              <p className="font-medium">
                Your search did not match any report names. Here&apos;s what you can try:
              </p>
              <div className="mb-4">
                <ul className="ml-3 mt-2 list-disc">
                  <li>Double-check the spelling of the report name</li>
                  <li>Check for typographical errors</li>
                  <li>
                    If you&apos;re unsure of the exact name, use a partial term or fewer characters
                  </li>
                </ul>
              </div>
            </div>
          ),
          emptyTitle: (
            <div className="mb-3 w-384px overflow-hidden text-center">
              <p className="text-lg font-semibold text-gray-600">
                No results found for reports with the term{' '}
              </p>
              <p
                className="w-full overflow-hidden truncate text-lg font-bold text-primary-400"
                title={searchQuery}
              >
                &apos;{searchQuery}&apos;
              </p>
            </div>
          ),
          buttonLabel: 'Search Again',
        }}
      />
    ),
    [ReportsDisplayState.Default]: children,
  };

  return stateMap[resultsDisplayState];
};
