import { LayerFilterKey, TemplateFilterKey } from '@/components/FilterMenu';
import { ReportV2 } from '@/graphql/codegen/graphql';
import { create } from 'zustand';

export interface TemplateFieldFilter {
  fieldId: string;
  optionId: string;
}

export interface CreateReportCondition {
  annotation2d: boolean;
  annotation3d: boolean;
  linked: boolean;
  search: string;
  templateFilters: Map<string, TemplateFieldFilter[]>;
}
export type CreateReportSort = 'name' | 'createdAt' | 'updatedAt';

interface LayoutState {
  annotationIds: string[];
  createReportCondition: CreateReportCondition;
  createReportSort: CreateReportSort;
  projectId: null | string;
  projectName: null | string;
  description: null | string;
  reportId: null | string;
  showCreateReport: boolean;
  showEditReport: boolean;
  storedTemplateFilterKeys: Set<TemplateFilterKey>;
  storedLayerFilterKeys: Set<LayerFilterKey>;
}

export const useLayout = create<LayoutState>()(() => {
  return {
    createReportCondition: {
      annotation2d: true,
      annotation3d: true,
      linked: false,
      search: '',
      templateFilters: new Map(),
    },
    createReportSort: 'name',
    createReportView: 'list',
    showCreateReport: false,
    showEditReport: false,
    projectName: null,
    description: null,
    projectId: null,
    reportId: null,
    annotationIds: [],
    storedTemplateFilterKeys: new Set(),
    storedLayerFilterKeys: new Set(),
  };
});

export const setStoredTemplateFilterKeys = (keys: Set<TemplateFilterKey>) => {
  useLayout.setState({ storedTemplateFilterKeys: keys });
};

export const setStoredLayerFilterKeys = (keys: Set<LayerFilterKey>) => {
  useLayout.setState({ storedLayerFilterKeys: keys });
};

export const clearStoredFilterKeys = () => {
  useLayout.setState({ storedTemplateFilterKeys: new Set(), storedLayerFilterKeys: new Set() });
};

export const openCreateReport = () => {
  useLayout.setState({ showCreateReport: true });
};

export const closeCreateReport = () => {
  useLayout.setState({
    showCreateReport: false,
    projectId: null,
    annotationIds: [],
    projectName: null,
    description: null,
  });
};

export const openEditReport = (report: ReportV2) => {
  useLayout.setState({
    showEditReport: true,
    projectId: report.projectId,
    annotationIds: report.annotationIds as string[],
    projectName: report.name,
    description: report.description,
    reportId: report.id,
  });
};

export const closeEditReport = () => {
  useLayout.setState({
    showEditReport: false,
    projectId: null,
    annotationIds: [],
    projectName: null,
    description: null,
    reportId: null,
  });
};

export const switchCreateReportSort = (sort: CreateReportSort) => {
  useLayout.setState({ createReportSort: sort });
};

export const selectProject = (projectId: null | string) => {
  useLayout.setState({ projectId });
};

export const selectAnnotations = (annotationIds: string[]) => {
  useLayout.setState(state => {
    const uniq = new Set([...state.annotationIds, ...annotationIds]);
    return { annotationIds: [...uniq] };
  });
};

export const selectAnnotation = (annotationId: string) => {
  useLayout.setState(state => {
    return { annotationIds: [...state.annotationIds, annotationId] };
  });
};

export const deselectAnnotation = (annotationId: string) => {
  useLayout.setState(state => {
    return { annotationIds: state.annotationIds.filter(id => id !== annotationId) };
  });
};

export const deselectAnnotations = (annotationIds: string[]) => {
  useLayout.setState(state => {
    return { annotationIds: state.annotationIds.filter(id => !annotationIds.includes(id)) };
  });
};

export const clearAnnotations = () => {
  useLayout.setState({ annotationIds: [] });
};

export const setCreateReportCondition = (condition: Partial<CreateReportCondition>) => {
  useLayout.setState(state => {
    return {
      createReportCondition: {
        ...state.createReportCondition,
        ...condition,
      },
    };
  });
};
