export const IFCIcon = () => {
  return (
    <svg fill="none" height="12" viewBox="0 0 16 12" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.6875 3.3125V9H2.61328V3.3125H3.6875ZM5.92188 3.3125V9H4.84766V3.3125H5.92188ZM8.21484 5.76953V6.61719H5.64062V5.76953H8.21484ZM8.51562 3.3125V4.16016H5.64062V3.3125H8.51562ZM12.5742 7.12891H13.6484C13.6198 7.50911 13.5143 7.84635 13.332 8.14062C13.1497 8.43229 12.8958 8.66146 12.5703 8.82812C12.2448 8.99479 11.8503 9.07812 11.3867 9.07812C11.0299 9.07812 10.7083 9.01562 10.4219 8.89062C10.138 8.76302 9.89453 8.58203 9.69141 8.34766C9.49089 8.11068 9.33724 7.82682 9.23047 7.49609C9.1237 7.16276 9.07031 6.78906 9.07031 6.375V5.94141C9.07031 5.52734 9.125 5.15365 9.23438 4.82031C9.34375 4.48698 9.5 4.20312 9.70312 3.96875C9.90885 3.73177 10.1549 3.54948 10.4414 3.42188C10.7305 3.29427 11.0534 3.23047 11.4102 3.23047C11.8737 3.23047 12.2656 3.31641 12.5859 3.48828C12.9062 3.65755 13.1549 3.89062 13.332 4.1875C13.5091 4.48438 13.6159 4.82552 13.6523 5.21094H12.5781C12.5573 4.97135 12.5052 4.76823 12.4219 4.60156C12.3411 4.4349 12.2188 4.30859 12.0547 4.22266C11.8932 4.13411 11.6784 4.08984 11.4102 4.08984C11.2018 4.08984 11.0182 4.12891 10.8594 4.20703C10.7031 4.28516 10.5729 4.40234 10.4688 4.55859C10.3646 4.71224 10.2865 4.90495 10.2344 5.13672C10.1823 5.36589 10.1562 5.63151 10.1562 5.93359V6.375C10.1562 6.66406 10.1797 6.92318 10.2266 7.15234C10.2734 7.38151 10.3464 7.57552 10.4453 7.73438C10.5443 7.89323 10.6719 8.01432 10.8281 8.09766C10.9844 8.18099 11.1706 8.22266 11.3867 8.22266C11.6497 8.22266 11.8633 8.18099 12.0273 8.09766C12.194 8.01432 12.3203 7.89193 12.4062 7.73047C12.4948 7.56901 12.5508 7.36849 12.5742 7.12891Z"
        fill="currentColor"
      />
      <rect height="11" rx="1.5" stroke="currentColor" width="15" x="0.5" y="0.5" />
    </svg>
  );
};
