import { FilterOptions, LayerFilterCategory, LayerFilterKey } from './LayerFilterKey';
import { Checkbox } from '@/components/Checkbox';
import { setStoredLayerFilterKeys, useLayout } from '@/stores/layout';
import { cn } from '@/utils/classname';

export interface LayerFilterGroupProps {
  category: LayerFilterCategory;
}

export const LayerFilterGroup = ({ category }: LayerFilterGroupProps) => {
  const storedLayerFilterKeys = useLayout(state => state.storedLayerFilterKeys);
  const options = FilterOptions[category];

  const toggleFilter = (key: LayerFilterKey) => {
    if (storedLayerFilterKeys.has(key)) {
      storedLayerFilterKeys.delete(key);
    } else {
      storedLayerFilterKeys.add(key);
    }
    setStoredLayerFilterKeys(new Set(storedLayerFilterKeys));
  };

  return (
    <>
      {options.map(([value, label]) => (
        <div
          className={cn('flex', 'flex-row', 'items-center', 'justify-start', 'gap-3', 'py-2')}
          key={value}
        >
          <Checkbox
            checked={storedLayerFilterKeys.has(value)}
            setToggleCheckbox={() => toggleFilter(value)}
            small
          />
          <p className={cn('color-neutral-800', 'typo-text-s')}>{label}</p>
        </div>
      ))}
    </>
  );
};
