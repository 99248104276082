import { clearAnnotations, useLayout } from '@/stores/layout';
import { Annotation } from '@/utils/entities';
import { Button, QuickList } from '@skand/ui';
import { useMemo } from 'react';
import { ListSelectedRow } from './ListSelectedRow';

export interface ListSelectedProps {
  annotations: Annotation[];
}

export const ListSelected = ({ annotations }: ListSelectedProps) => {
  const selectedAnnotationIds = useLayout(state => state.annotationIds);
  const selectedAnnotations = useMemo(
    () => annotations.filter(annotation => selectedAnnotationIds.includes(annotation.id)),
    [annotations, selectedAnnotationIds],
  );

  return (
    <div className="h-100% flex flex-col">
      <div className="h-fit flex flex-none items-center justify-between b-b-1 b-b-neutral-500 b-b-solid pb-2 pt-3">
        <p className="uppercase color-neutral-800 typo-button-xs">
          Selected annotations ({selectedAnnotationIds.length})
        </p>
        <Button disabled={selectedAnnotationIds.length === 0} onClick={clearAnnotations} size="xs">
          Remove all
        </Button>
      </div>

      <div className="flex-1 overflow-auto">
        <QuickList itemCount={selectedAnnotations.length}>
          {({ index }) => (
            <ListSelectedRow
              annotation={selectedAnnotations[index]}
              key={selectedAnnotations[index]?.id}
            />
          )}
        </QuickList>
      </div>
    </div>
  );
};
