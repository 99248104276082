import {
  deselectAnnotation,
  deselectAnnotations,
  selectAnnotation,
  selectAnnotations,
} from '@/stores/layout';
import { Annotation } from '@/utils/entities';
import { ChangeEvent } from 'react';

export type IsNodeChecked = (
  annotations: Annotation[],
  annotationIdsObject: Record<string, boolean>,
) => boolean;

export type HandleNodeChange = (annotations: Annotation[], isChecked: boolean) => void;

export type HandleAnnotationNodeChange = (e: ChangeEvent<HTMLInputElement>, id: string) => void;

export const useTreeNodeActions = () => {
  const isNodeChecked = (
    annotations: Annotation[],
    annotationIdsObject: Record<string, boolean>,
  ) => {
    if (annotations.length === 0) {
      return false;
    }
    for (let i = 0; i < annotations.length; i++) {
      if (!annotationIdsObject[annotations[i].id]) {
        return false;
      }
    }
    return true;
  };

  const handleNodeChange = (annotations: Annotation[], isChecked: boolean) => {
    const annotationIds = annotations.map(annotation => annotation.id);
    if (isChecked) {
      deselectAnnotations(annotationIds);
    } else {
      selectAnnotations(annotationIds);
    }
  };

  const handleAnnotationNodeChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    if (e.target.checked) {
      selectAnnotation(id);
    } else {
      deselectAnnotation(id);
    }
  };

  return [isNodeChecked, handleNodeChange, handleAnnotationNodeChange];
};
