import { LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';

export const useAnnotationTemplates = (projectId?: null | string) => {
  const response = useQuery({
    enabled: !!projectId,
    queryFn: () =>
      request(LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID, { projectId: projectId as string }),
    queryKey: ['LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID', projectId],
  });

  return response;
};
