import { setStoredLayerFilterKeys, setStoredTemplateFilterKeys, useLayout } from '@/stores/layout';
import { cn } from '@/utils/classname';
import { Button } from '@skand/ui';
import { LayerFilterTab, TemplateFilterTab } from '.';

export interface FilterMenuProps {
  closeMenu: () => void;
  className?: string;
}

export const FilterMenu = ({ closeMenu }: FilterMenuProps) => {
  const clearFilters = () => {
    setStoredLayerFilterKeys(new Set());
    setStoredTemplateFilterKeys(new Set());
  };

  const storedTemplateFilterKeys = useLayout(state => state.storedTemplateFilterKeys);
  const storedLayerFilterKeys = useLayout(state => state.storedLayerFilterKeys);

  return (
    <div
      className={cn('absolute', 'top-82', 'right-9', 'w-368px', 'z-2', 'min-h-30dvh max-h-40dvh')}
    >
      <div
        className={cn(
          'overflow-y-auto',
          'border-neutral-400',
          'border-1',
          'border-solid',
          'rounded-lg',
          'bg-neutral-100',
          'min-h-30dvh max-h-40dvh',
        )}
      >
        <div className={cn('flex', 'sticky', 'top-0', ' bg-neutral-100', 'z-10')}>
          <div
            className={cn(
              'p-3',
              'flex',
              'flex-row',
              'justify-between',
              'items-center',
              'flex-1',
              'border',
              'border-b-solid',
              'border',
              'border-neutral-300',
            )}
          >
            <p className={cn('typo-text-s', 'color-neutral-500')}>Annotations</p>
            <div
              className={cn(
                'i-skand-close',
                'text-3',
                'text-neutral-400',
                'cursor-pointer',
                ' p-1',
              )}
              onClick={closeMenu}
            />
          </div>
        </div>
        <div className={cn('flex-1  overflow-y-auto')}>
          <LayerFilterTab />
          <TemplateFilterTab />
        </div>
        <div
          className={cn(
            'flex',
            'items-center',
            'justify-between',
            'gap-2',
            'bottom-0',
            'sticky',
            'bg-white',
            'p-3',
          )}
        >
          <Button
            className={cn('w-full')}
            disabled={storedTemplateFilterKeys.size === 0 && storedLayerFilterKeys.size === 0}
            onClick={clearFilters}
            size="s"
          >
            Clear filter
          </Button>
        </div>
      </div>
    </div>
  );
};
