import { ReportV2 } from '@/graphql/codegen/graphql';
import { cn } from '@/utils/classname';
import { HeaderContext } from '@tanstack/react-table';

export interface HeaderProps {
  header: HeaderContext<Solid<ReportV2, 'id' | 'name'>, undefined | string>;
  title: string;
}

export const Header = ({ header, title }: HeaderProps) => {
  return (
    <div className="flex flex-1 items-center justify-start gap-2 whitespace-nowrap">
      <p className="uppercase color-neutral-800 typo-text-xs-em">{title}</p>
      <div
        className={cn('i-skand-dropdown color-neutral-400 cursor-pointer', {
          'text-blue-500 rotate-180	': header.column.getIsSorted() === 'asc',
          'text-red-500': header.column.getIsSorted() === 'desc',
          'text-gray-500 ': header.column.getIsSorted() === undefined,
        })}
        onClick={header.column.getToggleSortingHandler()}
      />
    </div>
  );
};
