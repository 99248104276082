import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { useLayout } from '@/stores/layout';
import { FilterProps } from '../ReportPage';
import { Filter } from './Filter';
import { TreeView } from './TreeView';
type SelectAnnotationProps = FilterProps;

export const SelectAnnotation = ({
  enabledFilterMenu,
  setEnabledFilterMenu,
}: SelectAnnotationProps) => {
  const projectId = useLayout(state => state.projectId);
  const entities = useFetchSceneEntities(projectId);

  return (
    <div className="mt-3 flex flex-1 flex-col overflow-auto b-1 b-neutral-400 rounded-2 b-solid">
      {projectId ? (
        <div className="flex-1 flex-col overflow-hidden">
          <Filter
            enabledFilterMenu={enabledFilterMenu}
            setEnabledFilterMenu={setEnabledFilterMenu}
          />
          <TreeView
            annotationGroups={entities.annotationGroups}
            isLoading={entities.areQueriesStillLoading}
            layerGroups={entities.layerGroups}
            layers={entities.layers}
          />
        </div>
      ) : (
        <div className="flex flex-1 items-center justify-center">
          <p className="color-neutral-400 typo-text-m">
            Select a project to view available annotations
          </p>
        </div>
      )}
    </div>
  );
};
