import { REPORTS_ROOT } from '@/constants/paths';
import { ImageFile, ReportV2 } from '@/graphql/codegen/graphql';
import { openEditReport } from '@/stores/layout';
import { Button } from '@skand/ui';
import { Link } from 'react-router-dom';
import { Download } from './Download';
import { More } from './More';

export const Actions = ({ hasEditAccess, row }: { hasEditAccess: boolean; row: ReportV2 }) => {
  const rowId = row.id ?? '';
  const fileName = row.name ?? '';
  const isPdfGenerating = row.isPdfGenerating ?? false;
  const pdfFileId = row.pdfFile ?? null;
  const pdfFile = (row.pdfFile as ImageFile) ?? null;

  return (
    <div className="w-full flex items-center justify-end gap-2 color-neutral-800">
      <Link target="_blank" to={`${REPORTS_ROOT}/${rowId}/view`}>
        <Button
          className="cursor-pointer color-neutral-800"
          disabled={isPdfGenerating || !pdfFileId}
          primary
          size="s"
        >
          View
        </Button>
      </Link>

      <Button
        className="cursor-pointer color-neutral-800"
        disabled={isPdfGenerating || !hasEditAccess}
        onClick={() => openEditReport(row)}
        size="s"
      >
        Edit
      </Button>

      <div className="relative flex">
        <Download isPdfGenerating={isPdfGenerating} pdfFile={pdfFile} rowId={rowId} />
      </div>

      <div className="relative flex">
        <More
          fileName={fileName}
          hasEditAccess={hasEditAccess}
          isPdfGenerating={isPdfGenerating}
          rowId={rowId}
        />
      </div>
    </div>
  );
};
