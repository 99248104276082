export const PointCloudIcon = () => {
  return (
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.16 2.7136C2.45823 2.7136 2.7 2.47062 2.7 2.17089C2.7 1.87115 2.45823 1.62817 2.16 1.62817C1.86176 1.62817 1.62 1.87115 1.62 2.17089C1.62 2.47062 1.86176 2.7136 2.16 2.7136Z"
        fill="currentColor"
      />
      <path
        d="M4.62008 2.7136C4.91831 2.7136 5.16008 2.47062 5.16008 2.17089C5.16008 1.87115 4.91831 1.62817 4.62008 1.62817C4.32184 1.62817 4.08008 1.87115 4.08008 2.17089C4.08008 2.47062 4.32184 2.7136 4.62008 2.7136Z"
        fill="currentColor"
      />
      <path
        d="M7.37997 2.7136C7.6782 2.7136 7.91997 2.47062 7.91997 2.17089C7.91997 1.87115 7.6782 1.62817 7.37997 1.62817C7.08173 1.62817 6.83997 1.87115 6.83997 2.17089C6.83997 2.47062 7.08173 2.7136 7.37997 2.7136Z"
        fill="currentColor"
      />
      <path
        d="M9.84005 2.7136C10.1383 2.7136 10.38 2.47062 10.38 2.17089C10.38 1.87115 10.1383 1.62817 9.84005 1.62817C9.54181 1.62817 9.30005 1.87115 9.30005 2.17089C9.30005 2.47062 9.54181 2.7136 9.84005 2.7136Z"
        fill="currentColor"
      />
      <path
        d="M2.16 10.3719C2.45823 10.3719 2.7 10.1289 2.7 9.82915C2.7 9.52942 2.45823 9.28644 2.16 9.28644C1.86176 9.28644 1.62 9.52942 1.62 9.82915C1.62 10.1289 1.86176 10.3719 2.16 10.3719Z"
        fill="currentColor"
      />
      <path
        d="M4.62008 10.3719C4.91831 10.3719 5.16008 10.1289 5.16008 9.82915C5.16008 9.52942 4.91831 9.28644 4.62008 9.28644C4.32184 9.28644 4.08008 9.52942 4.08008 9.82915C4.08008 10.1289 4.32184 10.3719 4.62008 10.3719Z"
        fill="currentColor"
      />
      <path
        d="M7.37997 10.3719C7.6782 10.3719 7.91997 10.1289 7.91997 9.82915C7.91997 9.52942 7.6782 9.28644 7.37997 9.28644C7.08173 9.28644 6.83997 9.52942 6.83997 9.82915C6.83997 10.1289 7.08173 10.3719 7.37997 10.3719Z"
        fill="currentColor"
      />
      <path
        d="M9.84005 10.3719C10.1383 10.3719 10.38 10.1289 10.38 9.82915C10.38 9.52942 10.1383 9.28644 9.84005 9.28644C9.54181 9.28644 9.30005 9.52942 9.30005 9.82915C9.30005 10.1289 9.54181 10.3719 9.84005 10.3719Z"
        fill="currentColor"
      />
      <path
        d="M4.61995 0.603015C4.78563 0.603015 4.91995 0.468026 4.91995 0.301508C4.91995 0.13499 4.78563 0 4.61995 0C4.45426 0 4.31995 0.13499 4.31995 0.301508C4.31995 0.468026 4.45426 0.603015 4.61995 0.603015Z"
        fill="currentColor"
      />
      <path
        d="M7.38008 0.603015C7.54576 0.603015 7.68008 0.468026 7.68008 0.301508C7.68008 0.13499 7.54576 0 7.38008 0C7.21439 0 7.08008 0.13499 7.08008 0.301508C7.08008 0.468026 7.21439 0.603015 7.38008 0.603015Z"
        fill="currentColor"
      />
      <path
        d="M4.61995 12C4.78563 12 4.91995 11.865 4.91995 11.6985C4.91995 11.532 4.78563 11.397 4.61995 11.397C4.45426 11.397 4.31995 11.532 4.31995 11.6985C4.31995 11.865 4.45426 12 4.61995 12Z"
        fill="currentColor"
      />
      <path
        d="M7.38008 12C7.54576 12 7.68008 11.865 7.68008 11.6985C7.68008 11.532 7.54576 11.397 7.38008 11.397C7.21439 11.397 7.08008 11.532 7.08008 11.6985C7.08008 11.865 7.21439 12 7.38008 12Z"
        fill="currentColor"
      />
      <path
        d="M2.16 5.12559C2.45823 5.12559 2.7 4.88261 2.7 4.58287C2.7 4.28314 2.45823 4.04016 2.16 4.04016C1.86176 4.04016 1.62 4.28314 1.62 4.58287C1.62 4.88261 1.86176 5.12559 2.16 5.12559Z"
        fill="currentColor"
      />
      <path
        d="M9.84005 5.12559C10.1383 5.12559 10.38 4.88261 10.38 4.58287C10.38 4.28314 10.1383 4.04016 9.84005 4.04016C9.54181 4.04016 9.30005 4.28314 9.30005 4.58287C9.30005 4.88261 9.54181 5.12559 9.84005 5.12559Z"
        fill="currentColor"
      />
      <path
        d="M4.62003 5.42715C5.08395 5.42715 5.46003 5.04918 5.46003 4.58293C5.46003 4.11668 5.08395 3.73871 4.62003 3.73871C4.15611 3.73871 3.78003 4.11668 3.78003 4.58293C3.78003 5.04918 4.15611 5.42715 4.62003 5.42715Z"
        fill="currentColor"
      />
      <path
        d="M7.37992 5.42715C7.84384 5.42715 8.21992 5.04918 8.21992 4.58293C8.21992 4.11668 7.84384 3.73871 7.37992 3.73871C6.916 3.73871 6.53992 4.11668 6.53992 4.58293C6.53992 5.04918 6.916 5.42715 7.37992 5.42715Z"
        fill="currentColor"
      />
      <path
        d="M2.16 7.89952C2.45823 7.89952 2.7 7.65653 2.7 7.3568C2.7 7.05707 2.45823 6.81409 2.16 6.81409C1.86176 6.81409 1.62 7.05707 1.62 7.3568C1.62 7.65653 1.86176 7.89952 2.16 7.89952Z"
        fill="currentColor"
      />
      <path
        d="M9.84005 7.89952C10.1383 7.89952 10.38 7.65653 10.38 7.3568C10.38 7.05707 10.1383 6.81409 9.84005 6.81409C9.54181 6.81409 9.30005 7.05707 9.30005 7.3568C9.30005 7.65653 9.54181 7.89952 9.84005 7.89952Z"
        fill="currentColor"
      />
      <path
        d="M4.62003 8.20102C5.08395 8.20102 5.46003 7.82305 5.46003 7.35679C5.46003 6.89054 5.08395 6.51257 4.62003 6.51257C4.15611 6.51257 3.78003 6.89054 3.78003 7.35679C3.78003 7.82305 4.15611 8.20102 4.62003 8.20102Z"
        fill="currentColor"
      />
      <path
        d="M7.37992 8.20102C7.84384 8.20102 8.21992 7.82305 8.21992 7.35679C8.21992 6.89054 7.84384 6.51257 7.37992 6.51257C6.916 6.51257 6.53992 6.89054 6.53992 7.35679C6.53992 7.82305 6.916 8.20102 7.37992 8.20102Z"
        fill="currentColor"
      />
      <path
        d="M11.7 4.88439C11.8657 4.88439 12 4.7494 12 4.58288C12 4.41636 11.8657 4.28137 11.7 4.28137C11.5343 4.28137 11.4 4.41636 11.4 4.58288C11.4 4.7494 11.5343 4.88439 11.7 4.88439Z"
        fill="currentColor"
      />
      <path
        d="M11.7 7.65831C11.8657 7.65831 12 7.52332 12 7.35681C12 7.19029 11.8657 7.0553 11.7 7.0553C11.5343 7.0553 11.4 7.19029 11.4 7.35681C11.4 7.52332 11.5343 7.65831 11.7 7.65831Z"
        fill="currentColor"
      />
      <path
        d="M0.3 4.88439C0.465685 4.88439 0.6 4.7494 0.6 4.58288C0.6 4.41636 0.465685 4.28137 0.3 4.28137C0.134315 4.28137 0 4.41636 0 4.58288C0 4.7494 0.134315 4.88439 0.3 4.88439Z"
        fill="currentColor"
      />
      <path
        d="M0.3 7.65831C0.465685 7.65831 0.6 7.52332 0.6 7.35681C0.6 7.19029 0.465685 7.0553 0.3 7.0553C0.134315 7.0553 0 7.19029 0 7.35681C0 7.52332 0.134315 7.65831 0.3 7.65831Z"
        fill="currentColor"
      />
    </svg>
  );
};
