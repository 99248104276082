export const ImageIcon = () => {
  return (
    <svg fill="none" height="14" viewBox="0 0 12 14" width="12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.0802 10.2866L5.00533 8.47754L7.25132 10.6484L9.56148 8.47754L11.4224 10.2866V12.3971H3.0802V10.2866Z"
        fill="currentColor"
      />
      <path
        d="M11.2398 12.5174H3.34677C3.21843 12.5174 3.09009 12.3968 3.09009 12.2762V3.83398C3.09009 3.71338 3.21843 3.59277 3.34677 3.59277H11.2398C11.3682 3.59277 11.4965 3.71338 11.4965 3.83398V12.2762C11.4965 12.3968 11.3682 12.5174 11.2398 12.5174Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1.008"
      />
      <path
        d="M3.01604 10.4673H0.770051C0.641709 10.4673 0.513367 10.3467 0.513367 10.2261V1.78393C0.513367 1.60302 0.641709 1.48242 0.770051 1.48242H8.6631C8.85561 1.48242 8.98396 1.60302 8.98396 1.72363V3.53267"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1.008"
      />
    </svg>
  );
};
