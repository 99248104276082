import { Fragment, ReactNode } from 'react';

export interface RepeatProps {
  n: number;
  children: ReactNode;
}

export const Repeat = ({ n, children }: RepeatProps) => {
  return (
    <>
      {Array(n)
        .fill(0)
        .map((_, i) => {
          return <Fragment key={i}>{children}</Fragment>;
        })}
    </>
  );
};
