import { isEmpty } from '@/utils/empty';
import {
  Annotation,
  AnnotationTemplate,
  AnnotationTemplateSelectField,
  AnnotationTemplateUrlField,
  AnnotationImageField,
  ImageFile,
  ReportV2,
  AnnotationTemplateImageField,
  AnnotationField,
  AnnotationSelectField,
  AnnotationUrlField,
  AnnotationDateField,
  AnnotationTextField,
  AnnotationFileField,
} from '@/graphql/codegen/graphql';
import { GRAPHQL_URL } from '@/constants/env';

// Type guards
const isImageField = (field: AnnotationField): field is AnnotationImageField => 'file' in field;
const isSelectField = (field: AnnotationField): field is AnnotationSelectField =>
  'optionId' in field;
const isUrlField = (field: AnnotationField): field is AnnotationUrlField => 'urls' in field;
const isDateField = (field: AnnotationField): field is AnnotationDateField => 'start' in field;
const isTextField = (field: AnnotationField): field is AnnotationTextField => 'text' in field;
const isFileField = (field: AnnotationField): field is AnnotationFileField => 'files' in field;

const getSignedURL = (
  reportId: ReportV2['id'],
  annotationId: Annotation['id'],
  fileId: ImageFile['id'],
) => {
  const base = GRAPHQL_URL.slice(0, -8);
  return `${base}/api/v3/resources/reports/${reportId}/annotations/${annotationId}/files/${fileId}/signedUrl`;
};

const getTemplateFields = (
  reportId: ReportV2['id'],
  templates: AnnotationTemplate[],
  templateId: Annotation['templateId'],
  fields: AnnotationField[],
  annotationId: Annotation['id'],
) => {
  // Get relevant template
  const template = templates.find(tmp => tmp.id === templateId);

  const tempFields = [];
  if (!fields || !template || !template.fields) return;
  for (const field of fields) {
    if (field.type === 'TEXT' && field.name && isTextField(field)) {
      tempFields.push({
        type: field.type,
        name: field.name,
        value: field.text,
      });
    } else if (field.type === 'DATE' && field.name && isDateField(field)) {
      tempFields.push({
        type: field.type,
        name: field.name,
        value: field.start,
      });
    } else if (field.type === 'URL' && isUrlField(field)) {
      for (const templateFields of template.fields as AnnotationTemplateUrlField[]) {
        if (templateFields.id === field.fieldId) {
          tempFields.push({
            type: field.type,
            name: templateFields.name,
            urls: field.urls,
          });
        }
      }
    } else if (field.type === 'SELECT' && field.name && isSelectField(field)) {
      for (const templateFields of template.fields as AnnotationTemplateSelectField[]) {
        if (templateFields.id === field.fieldId) {
          tempFields.push({
            type: field.type,
            name: field.name,
            value: templateFields.options?.find(option => option?.id === field.optionId)?.value,
          });
        }
      }
    } else if (field.type === 'IMAGE' && isImageField(field) && field.file) {
      tempFields.push({
        type: field.type,
        name: (field.file as ImageFile).fileName,
        value: getSignedURL(reportId, annotationId, field.fileId),
      });
    } else if (field.type === 'FILE' && isFileField(field) && field.files) {
      for (const templateFields of template.fields as AnnotationTemplateImageField[]) {
        if (templateFields.id === field.fieldId) {
          tempFields.push({
            type: field.type,
            name: templateFields.name,
            files: field.files.map(file => {
              if (file)
                return {
                  name: file.name,
                  url: getSignedURL(reportId, annotationId, file.fileId),
                };
            }),
          });
        }
      }
    }
  }

  return tempFields;
};

export const createAnnotations = (
  reportId: ReportV2['id'],
  annotations: Annotation[],
  annotationTemplates: AnnotationTemplate[],
) => {
  if (!isEmpty(annotations) && !isEmpty(annotationTemplates)) {
    const items = [];
    for (const annotation of annotations) {
      if (!annotation.annotation3d) continue;
      const fields = getTemplateFields(
        reportId,
        annotationTemplates,
        annotation.templateId,
        annotation.fields as AnnotationField[],
        annotation.annotationId,
      );
      const item = {
        name: annotation.name,
        color: annotation.color,
        annotation3d: {
          shapeType: annotation.annotation3d.shapeType,
          positions: annotation.annotation3d.positions,
        },
        fields,
      };

      items.push(item);
    }

    return items;
  }
};
