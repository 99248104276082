import { cn } from '@/utils/classname';

export interface CheckboxProps {
  checked: boolean;
  setToggleCheckbox: (checked: boolean) => void;
  disabled?: boolean;
  small?: boolean;
  checkedPartial?: boolean;
}

export const Checkbox = ({
  checked,
  disabled,
  checkedPartial = false,
  small = false,
  setToggleCheckbox,
}: CheckboxProps) => {
  return (
    <div
      className={cn('cursor-pointer')}
      onClick={() => {
        if (!disabled) {
          setToggleCheckbox(!checked);
        }
      }}
    >
      {checkedPartial ? (
        <PartiallyCheckedSvg small={small} />
      ) : checked ? (
        <CheckedSvg small={small} />
      ) : (
        <UncheckedSvg small={small} />
      )}
    </div>
  );
};

const CheckedSvg = ({ small }: { small: boolean }) => (
  <div className={cn('i-skand-boxchecked color-neutral-600', small && 'w-12px h-12px')} />
);

const PartiallyCheckedSvg = ({ small }: { small: boolean }) => (
  <div className={cn('color-neutral-600 ', small && 'translate-y-1px')}>
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#726F74" height="12" rx="2" width="12" />
      <path d="M2 6H5H10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);

const UncheckedSvg = ({ small }: { small: boolean }) => (
  <div className={cn('i-skand-boxunchecked color-neutral-600', small && 'w-12px h-12px')} />
);
