import { useReportWithPayload } from '@/hooks/useReportWithPayload';
import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Button, Menu, MenuItem, toast } from '@skand/ui';
import { Matrix4, Vector3, Quaternion, Ellipsoid } from '@skand/math';

import { useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import ExcelJs from 'exceljs';
import { useProjects } from '@/hooks/useProjects';
import {
  Annotation,
  AnnotationDateField,
  AnnotationField,
  AnnotationFileField,
  AnnotationImageField,
  AnnotationSelectField,
  AnnotationTemplate,
  AnnotationTemplateField,
  AnnotationTemplateSelectField,
  AnnotationTextField,
  AnnotationUrlField,
  ImageFile,
  Maybe,
  Project,
  ReportV2,
  SceneEntity,
} from '@/graphql/codegen/graphql';
import { isEmpty } from '@/utils/empty';
import { calculateEdgeLengths, calculatePolygonArea } from '@skand/viewer-component-v2';
import { GRAPHQL_URL } from '@/constants/env';
import { AnnotationCSVExporter, Annotation as CSVAnnotation } from '@skand/data-3d-loader';
import { createAnnotations } from '@/utils/transformers';
import { ANNOTATION_CSV } from '@/utils/split';
import { useTreatments } from '@splitsoftware/splitio-react';
const XLSX_COLUMN_KEYS = {
  ID: 'id',
  NAME: 'name',
  PROJECT_NAME: 'projectName',
  GROUP_NAME: 'groupName',
  IMAGE_2D_URL: 'image2dUrl',
  IMAGE_2D_POINTS: 'image2dPoints',
  LINE_LENGTH: 'lineLength',
  AREA: 'area',
  SHAPE_TYPE: 'shapeType',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  EXPLORE_URL: 'exploreUrl',
};

const ANNOTATION_SHAPE_TYPE = {
  POINT: 'POINT',
  POLYLINE: 'POLYLINE',
  POLYGON: 'POLYGON',
};

const formatURL = (reportId: string, annotationId: string, fileId: string) => {
  const base = GRAPHQL_URL.slice(0, -8);
  return `${base}/api/v3/resources/reports/${reportId}/annotations/${annotationId}/files/${fileId}/signedUrl`;
};

export const Download = ({
  rowId,
  isPdfGenerating,
  pdfFile,
}: {
  rowId: string;
  isPdfGenerating: boolean;
  pdfFile: ImageFile | null;
}) => {
  const projectResponse = useProjects();
  const refetch = useReportWithPayload(rowId);
  const treatment = useTreatments([ANNOTATION_CSV]);
  const annotationCSVFlag = treatment[ANNOTATION_CSV].treatment === 'on';

  const [open, setOpen] = useState(false);

  const projects = useMemo(() => {
    return projectResponse?.data?.listProjectsByAccountContext ?? [];
  }, [projectResponse.data?.listProjectsByAccountContext]);

  const getTotalLength = (annotation: Annotation) => {
    if (annotation.annotation3d?.positions && annotation.annotation3d.shapeType) {
      const points = annotation.annotation3d.positions?.map(
        point => new Vector3(point?.x ?? 0, point?.y ?? 0, point?.z ?? 0),
      );

      const isClosed = annotation.annotation3d.shapeType === ANNOTATION_SHAPE_TYPE.POLYGON;
      const lengths = calculateEdgeLengths(points, isClosed);
      return lengths.reduce((prev, curr) => prev + curr, 0);
    }
  };

  const getArea = (annotation: Annotation) => {
    if (annotation.annotation3d?.positions && annotation.annotation3d.shapeType) {
      const points = annotation.annotation3d.positions?.map(
        point => new Vector3(point?.x ?? 0, point?.y ?? 0, point?.z ?? 0),
      );

      return calculatePolygonArea(points);
    }
  };

  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom-end',
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const prepareTransforms = (sceneEntitiesToRootArrays: SceneEntity[][]) => {
    const transforms: Map<string, Matrix4> = new Map();
    sceneEntitiesToRootArrays.forEach((sceneEntities: SceneEntity[]) => {
      const transform = new Matrix4();
      sceneEntities.forEach((sceneEntity: SceneEntity) => {
        const position = new Vector3(
          sceneEntity?.position?.x as number,
          sceneEntity?.position?.y as number,
          sceneEntity?.position?.z as number,
        );
        const rotation = new Quaternion(
          sceneEntity?.rotation?.x as number,
          sceneEntity?.rotation?.y as number,
          sceneEntity?.rotation?.z as number,
          sceneEntity?.rotation?.w as number,
        );
        const scale = new Vector3(1, 1, 1);
        const local = new Matrix4().compose(position, rotation, scale);
        transform.premultiply(local);
      });
      transforms.set(sceneEntities[0].renderObjectId as string, transform);
    });
    return transforms;
  };

  const generateXlsx = async (
    report: ReportV2,
    sceneEntities: Maybe<SceneEntity>[],
    sceneEntitiesFromChildToRootArrays: SceneEntity[][],
  ) => {
    if (!report) return;

    const annotations = report.annotations as Annotation[];
    const annotationTemplates = report.annotationTemplates as AnnotationTemplate[];
    const workbook = new ExcelJs.Workbook();
    const transforms = prepareTransforms(sceneEntitiesFromChildToRootArrays);

    annotationTemplates.forEach((template: AnnotationTemplate) => {
      if (isEmpty(template.name) || isEmpty(template.fields)) return;
      const worksheet = workbook.addWorksheet(template.name);
      const columns = [
        {
          header: 'ID',
          key: XLSX_COLUMN_KEYS.ID,
        },
        {
          header: 'Name',
          key: XLSX_COLUMN_KEYS.NAME,
        },
        {
          header: 'Group Name',
          key: XLSX_COLUMN_KEYS.GROUP_NAME,
        },
        {
          header: 'Project Name',
          key: XLSX_COLUMN_KEYS.PROJECT_NAME,
        },
        {
          header: '3D Shape Type',
          key: XLSX_COLUMN_KEYS.SHAPE_TYPE,
        },
        {
          header: 'Line Length (m)',
          key: XLSX_COLUMN_KEYS.LINE_LENGTH,
        },
        {
          header: 'Area (sq. m)',
          key: XLSX_COLUMN_KEYS.AREA,
        },
        {
          header: 'Latitude',
          key: XLSX_COLUMN_KEYS.LATITUDE,
        },
        {
          header: 'Longitude',
          key: XLSX_COLUMN_KEYS.LONGITUDE,
        },
        {
          header: 'Link to Skand Explore',
          key: XLSX_COLUMN_KEYS.EXPLORE_URL,
        },
      ];

      const fieldColumns = [];

      for (const field of template.fields) {
        if (!isEmpty(field) && !isEmpty(field.name)) {
          fieldColumns.push({
            header: field.name,
            key: field.name,
            style: { alignment: { wrapText: field.type === 'FILE' } },
          });
        }
      }

      const Image2DColumns = [
        {
          header: '2D Image URL',
          key: XLSX_COLUMN_KEYS.IMAGE_2D_URL,
        },
        {
          header: '2D Image Points',
          key: XLSX_COLUMN_KEYS.IMAGE_2D_POINTS,
        },
      ];

      worksheet.columns = [...columns, ...fieldColumns, ...Image2DColumns];

      // find annotations that belong to this template
      const templateAnnotations = annotations.filter(
        (annotation: Annotation) => annotation.templateId === template.id,
      );
      templateAnnotations.forEach((annotation: Annotation) => {
        if (isEmpty(annotation) || isEmpty(annotation.annotation2d)) return;
        const newRow = worksheet.addRow([]);
        newRow.getCell(XLSX_COLUMN_KEYS.ID).value = annotation.id;
        newRow.getCell(XLSX_COLUMN_KEYS.NAME).value = annotation.name;
        newRow.getCell(XLSX_COLUMN_KEYS.GROUP_NAME).value = sceneEntities.find(
          group => group?.renderObject?.id === annotation.groupId,
        )?.name;
        newRow.getCell(XLSX_COLUMN_KEYS.PROJECT_NAME).value = (projects as Project[]).find(
          project => project.id === annotation.projectId,
        )?.name;

        const domain = window.location.origin;
        newRow.getCell(
          XLSX_COLUMN_KEYS.EXPLORE_URL,
        ).value = `${domain}/explore?project=${annotation.projectId}&annotation=${annotation.annotationId}`;

        newRow.getCell(XLSX_COLUMN_KEYS.IMAGE_2D_URL).value =
          annotation.annotation2d?.imageFileId &&
          formatURL(
            report.id as string,
            annotation.annotationId as string,
            annotation.annotation2d?.imageFileId as string,
          );

        newRow.getCell(XLSX_COLUMN_KEYS.IMAGE_2D_POINTS).value =
          annotation.annotation2d?.points && JSON.stringify(annotation.annotation2d.points);

        if (annotation.annotation3d) {
          newRow.getCell(XLSX_COLUMN_KEYS.SHAPE_TYPE).value = annotation.annotation3d.shapeType;

          const transform = transforms.get(annotation.groupId as string);

          if (transform) {
            const centroid = new Vector3();
            annotation.annotation3d.positions?.forEach(point => {
              const vector = new Vector3(
                point?.x as number,
                point?.y as number,
                point?.z as number,
              );
              vector.applyMatrix4(transform);
              centroid.add(vector);
            });
            centroid.divideScalar(annotation.annotation3d?.positions?.length as number);

            const cartographic = Ellipsoid.WGS_84.cartographicForPositionOnSurface(centroid);

            newRow.getCell(XLSX_COLUMN_KEYS.LONGITUDE).value =
              cartographic.longitude * (180 / Math.PI);
            newRow.getCell(XLSX_COLUMN_KEYS.LATITUDE).value =
              cartographic.latitude * (180 / Math.PI);
          }
        }

        if (annotation.annotation3d?.shapeType === ANNOTATION_SHAPE_TYPE.POLYLINE) {
          newRow.getCell(XLSX_COLUMN_KEYS.LINE_LENGTH).value = (
            getTotalLength(annotation) ?? 0
          ).toFixed(4);
        }
        if (annotation.annotation3d?.shapeType === ANNOTATION_SHAPE_TYPE.POLYGON) {
          newRow.getCell(XLSX_COLUMN_KEYS.LINE_LENGTH).value = (
            getTotalLength(annotation) ?? 0
          ).toFixed(4);
          newRow.getCell(XLSX_COLUMN_KEYS.AREA).value = (getArea(annotation) ?? 0).toFixed(4);
        }

        // for each field, find the value of the annotation
        (template.fields as AnnotationTemplateField[]).forEach(field => {
          let value = '';
          const annotationField = (annotation.fields as AnnotationField[]).find(
            item => item.fieldId === field.id,
          );
          if (!field.name) return;
          if (field.type === 'TEXT') {
            value = (annotationField as AnnotationTextField)?.text as string;
          } else if (field.type === 'DATE') {
            value = (annotationField as AnnotationDateField)?.end
              ? `${(annotationField as AnnotationDateField)?.start} - ${
                  (annotationField as AnnotationDateField)?.end
                }`
              : ((annotationField as AnnotationDateField)?.start as string);
          } else if (field.type === 'SELECT') {
            const options = (field as AnnotationTemplateSelectField).options;
            const option = options?.find(
              option => option?.id === (annotationField as AnnotationSelectField)?.optionId,
            );
            value = option?.value as string;
          } else if (field.type === 'URL') {
            value = (annotationField as AnnotationUrlField)?.urls
              ?.map(url => `${url?.name} (${url?.url})`)
              ?.join('\n') as string;
          } else if (field.type === 'FILE') {
            value = (annotationField as AnnotationFileField)?.files
              ?.map(file => {
                const url = formatURL(
                  report.id as string,
                  annotation.annotationId as string,
                  file?.fileId as string,
                );
                return `${file?.name} - ${url}`;
              })
              ?.join('\r\n') as string;
          } else if (field.type === 'IMAGE') {
            const fileId = (annotationField as AnnotationImageField)?.fileId;
            if (fileId) {
              value = formatURL(report.id as string, annotation.annotationId as string, fileId);
            }
          }
          newRow.getCell(field.name as string).value = value;
        });

        newRow.commit();
      });
    });

    // Add a default worksheet if empty
    if (workbook.worksheets.length === 0) {
      workbook.addWorksheet('No Annotations');
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: '	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const filename = `${report.name}.xlsx`;
    saveAs(blob, filename);
  };

  const handleGenerateXlsx = async () => {
    const response = await refetch();

    if (response) {
      generateXlsx(
        response.report,
        response.sceneEntities,
        response.sceneEntitiesFromChildToRootArrays,
      );
    }
  };

  const handleDownloadPdf = () => {
    if (!pdfFile) return;
    if (isEmpty(pdfFile.signedGetObjectDownloadUrl)) return;
    window.open(pdfFile.signedGetObjectDownloadUrl, '_blank');
  };

  const handleGenerateCSV = async () => {
    const response = await refetch();

    if (response) {
      const report = response.report as ReportV2;
      const annotations = createAnnotations(
        report.id as ReportV2['id'],
        report.annotations as Annotation[],
        report.annotationTemplates as AnnotationTemplate[],
      );
      if (annotations?.length === 0)
        return toast({
          type: 'warning',
          message: (dismiss: () => void) => {
            return (
              <>
                {`There're no annotations available `}
                <a className="underline typo-link-xs" onClick={dismiss}>
                  Dismiss
                </a>
              </>
            );
          },
        });
      const annotationExporter = new AnnotationCSVExporter();
      const csvFiles = await annotationExporter.read(annotations as CSVAnnotation[]);

      for (let i = 0; i < csvFiles.length; i++) {
        if (!annotations) continue;
        const csv = csvFiles[i];
        const blob = new Blob([csv], {
          type: 'text/csv',
        });
        const filename = `${annotations[i].name}.csv`;
        saveAs(blob, filename);
      }
    }
  };

  return (
    <>
      <Button
        active={open}
        className="flex cursor-pointer items-center gap-2 color-neutral-800"
        ref={refs.setReference}
        size="s"
        {...getReferenceProps()}
      >
        <span className="color-neutral-800">Download</span>
        <div className="i-skand-dropdown color-neutral-800" />
      </Button>

      {open && (
        <Menu
          className="z-1 w-89px"
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <MenuItem
            className="cursor-pointer"
            disabled={isPdfGenerating || !pdfFile}
            onClick={handleDownloadPdf}
          >
            PDF
          </MenuItem>
          <MenuItem className="cursor-pointer" onClick={handleGenerateXlsx}>
            XLSX
          </MenuItem>
          {annotationCSVFlag && (
            <MenuItem className="cursor-pointer" onClick={handleGenerateCSV}>
              CSV
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};
