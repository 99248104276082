import { RENDERER_TYPE } from '@/constants/annotation';
import { LayerFormatType } from '@/constants/layer';
import { AnnotationField, AnnotationRenderObject, SceneEntity } from '@/graphql/codegen/graphql';
import {
  LIST_ANNOTATIONS_BY_PROJECT_ID,
  LIST_LAYERS_BY_PROJECT_ID,
  LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES,
} from '@/graphql/queries';
import { request } from '@/graphql/request';
import { isEmpty } from '@/utils/empty';
import { Annotation, AnnotationGroup, Layer, LayerGroup } from '@/utils/entities';
import { Graph } from '@skand/math';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAnnotationTemplates } from './useAnnotationTemplates';

export const useFetchSceneEntities = (projectId?: null | string) => {
  const layersResult = useQuery({
    enabled: !!projectId,
    queryFn: () => request(LIST_LAYERS_BY_PROJECT_ID, { projectId: projectId as string }),
    queryKey: ['LIST_LAYERS_BY_PROJECT_ID', projectId],
  });
  const annotationsResult = useQuery({
    enabled: !!projectId,
    queryFn: () => request(LIST_ANNOTATIONS_BY_PROJECT_ID, { projectId: projectId as string }),
    queryKey: ['LIST_ANNOTATIONS_BY_PROJECT_ID', projectId],
  });
  const sceneEntitiesResult = useQuery({
    enabled: !!projectId,
    queryFn: () =>
      request(LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES, {
        projectId: projectId as string,
        rendererTypes: [],
      }),
    queryKey: ['LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES', projectId],
  });
  const templatesResult = useAnnotationTemplates(projectId);

  const areQueriesStillLoading =
    layersResult.isLoading ||
    annotationsResult.isLoading ||
    sceneEntitiesResult.isLoading ||
    templatesResult.isLoading;

  return useMemo(() => {
    const layers: Layer[] = [];
    const layerGroups: LayerGroup[] = [];
    const annotationGroups: AnnotationGroup[] = [];

    // Link nodes together
    const graph = new Graph<string>();
    const sceneEntityMap = new Map<string, SceneEntity>();
    const objectMap = new Map<string, Layer | LayerGroup | AnnotationGroup>();
    if (
      !isEmpty(sceneEntitiesResult.data) &&
      !isEmpty(sceneEntitiesResult.data.listSceneEntitiesByProjectIdAndRendererTypes) &&
      !isEmpty(layersResult.data) &&
      !isEmpty(layersResult.data.listLayersByProjectId) &&
      !isEmpty(annotationsResult.data) &&
      !isEmpty(annotationsResult.data.listAnnotationsByProjectId) &&
      !isEmpty(templatesResult.data) &&
      !isEmpty(templatesResult.data.listAnnotationTemplatesByProjectId)
    ) {
      // Pre-process scene entity graph
      for (const entity of sceneEntitiesResult.data.listSceneEntitiesByProjectIdAndRendererTypes) {
        if (!isEmpty(entity) && !isEmpty(entity.id)) {
          graph.addNode(entity.id);
          sceneEntityMap.set(entity.id, entity);

          // Define dependency
          if (!isEmpty(entity.parentSceneEntityId)) {
            graph.addEdge(entity.parentSceneEntityId, entity.id);
          }
        }
      }

      // Process each node in order
      const sorted = graph.topologicalSort() ?? [];
      for (const nodeId of sorted) {
        const node = sceneEntityMap.get(nodeId);
        if (!node) {
          continue;
        }

        const parentId = graph.getParents(nodeId)[0];
        const parent = objectMap.get(parentId) as Layer | undefined;
        if (parentId && !parent) {
          continue;
        }

        // Create the nodes by renderer type
        if (node.rendererType === null) {
          const group: LayerGroup = {
            type: 'layerGroup',
            id: node.id as string,
            name: node.name as string,
            sceneEntityId: node.id as string,
            parent,
          };
          layerGroups.push(group);
          objectMap.set(nodeId, group);
        } else if (node.rendererType === RENDERER_TYPE.ANNOTATION) {
          const annotationGroup = node.renderObject as AnnotationRenderObject;
          const group: AnnotationGroup = {
            type: 'annotationGroup',
            id: annotationGroup.id as string,
            name: node.name as string,
            sceneEntityId: node.id as string,
            createdAt: new Date(annotationGroup.createdAt as string),
            parent,
            annotations: [],
          };
          annotationGroups.push(group);
          objectMap.set(nodeId, group);
        } else if (
          node.rendererType !== RENDERER_TYPE.IMAGE_PROJECTION &&
          node.rendererType !== RENDERER_TYPE.PANORAMIC_IMAGE
        ) {
          const layerQuery = layersResult.data.listLayersByProjectId.find(
            layer => layer?.mainSceneEntityId === nodeId,
          );
          if (layerQuery) {
            const layerObject: Layer = {
              type: 'layer',
              id: layerQuery.id as string,
              name: layerQuery.name as string,
              sceneEntityId: layerQuery.mainSceneEntityId as string,
              formatType: layerQuery.formatType as LayerFormatType,
              parent,
            };
            layers.push(layerObject);
            objectMap.set(nodeId, layerObject);
          }
        }
      }

      // Populate groups with annotations
      for (const annotationQuery of annotationsResult.data.listAnnotationsByProjectId) {
        if (
          !isEmpty(annotationQuery) &&
          !isEmpty(annotationQuery.id) &&
          !isEmpty(annotationQuery.groupId) &&
          !isEmpty(annotationQuery.templateId)
        ) {
          const group = annotationGroups.find(group => group.id === annotationQuery.groupId);
          const template = templatesResult.data.listAnnotationTemplatesByProjectId.find(
            template => template?.id === annotationQuery.templateId,
          );
          if (group && template) {
            const fields: AnnotationField[] = [];
            for (const field of annotationQuery.fields ?? []) {
              if (!isEmpty(field)) {
                fields.push(field);
              }
            }
            const annotation: Annotation = {
              type: 'annotation',
              id: annotationQuery.annotationId as string,
              name: annotationQuery.name as string,
              versionId: annotationQuery.id,
              templateId: annotationQuery.templateId as string,
              createdAt: new Date(annotationQuery.createdAt as string),
              group,
              has2D: !isEmpty(annotationQuery.annotation2d?.shapeType),
              has3D: !isEmpty(annotationQuery.annotation3d?.shapeType),
              fields,
              template,
            };
            group.annotations.push(annotation);
          }
        }
      }
    }

    return {
      layerGroups,
      layers,
      annotationGroups,
      areQueriesStillLoading,
    };
  }, [
    annotationsResult.data,
    areQueriesStillLoading,
    layersResult.data,
    sceneEntitiesResult.data,
    templatesResult.data,
  ]);
};
