export const DXFIcon = () => {
  return (
    <svg fill="none" height="12" viewBox="0 0 19 12" width="19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.24219 9H3.01562L3.02344 8.15625H4.24219C4.57292 8.15625 4.85026 8.08333 5.07422 7.9375C5.29818 7.78906 5.46745 7.57682 5.58203 7.30078C5.69661 7.02214 5.75391 6.6888 5.75391 6.30078V6.00781C5.75391 5.70833 5.72135 5.44401 5.65625 5.21484C5.59115 4.98568 5.49479 4.79297 5.36719 4.63672C5.24219 4.48047 5.08724 4.36198 4.90234 4.28125C4.71745 4.20052 4.50521 4.16016 4.26562 4.16016H2.99219V3.3125H4.26562C4.64583 3.3125 4.99219 3.3763 5.30469 3.50391C5.61979 3.63151 5.89193 3.8151 6.12109 4.05469C6.35286 4.29167 6.52995 4.57552 6.65234 4.90625C6.77734 5.23698 6.83984 5.60677 6.83984 6.01562V6.30078C6.83984 6.70703 6.77734 7.07682 6.65234 7.41016C6.52995 7.74089 6.35286 8.02474 6.12109 8.26172C5.89193 8.4987 5.61849 8.68099 5.30078 8.80859C4.98307 8.9362 4.63021 9 4.24219 9ZM3.61719 3.3125V9H2.54297V3.3125H3.61719ZM8.52344 3.3125L9.66016 5.33984L10.8008 3.3125H12.0352L10.3477 6.13281L12.0781 9H10.832L9.66016 6.93359L8.49219 9H7.24219L8.97266 6.13281L7.28516 3.3125H8.52344ZM13.8125 3.3125V9H12.7383V3.3125H13.8125ZM16.1055 5.76953V6.61719H13.5312V5.76953H16.1055ZM16.4062 3.3125V4.16016H13.5312V3.3125H16.4062Z"
        fill="currentColor"
      />
      <rect height="11" rx="1.5" stroke="currentColor" width="18" x="0.5" y="0.5" />
    </svg>
  );
};
