import { Button, EmptyDesc, EmptyRoot, EmptyTitle, EmptyToast } from '@skand/ui';

interface EmptyResourceMessageProps {
  emptyResourceContent: {
    actionButton?: () => void;
    emptyDesc?: string | JSX.Element;
    emptyTitle: string | JSX.Element;
    buttonLabel?: string;
    emptyToast?: string;
  };
}

export const EmptyResourceMessage = ({
  emptyResourceContent: { actionButton, emptyDesc, emptyTitle, buttonLabel, emptyToast },
}: EmptyResourceMessageProps) => {
  return (
    <EmptyRoot className="h-full">
      <div className="flex flex-col items-center gap-3">
        {emptyToast && <EmptyToast message={emptyToast} />}
        <EmptyTitle>{emptyTitle}</EmptyTitle>
        <EmptyDesc>{emptyDesc}</EmptyDesc>
        {buttonLabel && (
          <Button className="w-full" filled onClick={actionButton} primary size="s">
            {buttonLabel}
          </Button>
        )}
      </div>
    </EmptyRoot>
  );
};
