import { FIND_REPORT_V2_BY_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (id: string) => ['FIND_REPORT_V2_BY_ID', id];

export const useReport = (id: null | string) => {
  const response = useQuery({
    enabled: !!id,
    queryFn: () => request(FIND_REPORT_V2_BY_ID, { reportId: id as string }),
    queryKey: getQueryKey(id as string),
    refetchOnWindowFocus: false,
  });

  const report = useMemo(() => {
    return response.data?.findReportV2ById || null;
  }, [response.data?.findReportV2ById]);

  return { response, report };
};

useReport.getQueryKey = getQueryKey;
