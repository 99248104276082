import { cn } from '@/utils/classname';
import { Annotation, SceneEntity } from '@/utils/entities';
import { TreeNodeProps as TreeNodePropsCore } from '@skand/ui';
import { useEffect, useMemo, useRef } from 'react';
import { AnnotationGroupNode } from './AnnotationGroupNode';
import { AnnotationNode } from './AnnotationNode';
import { LayerGroupNode } from './LayerGroupNode';
import { LayerNode } from './LayerNode';
import { IsNodeChecked, HandleNodeChange, HandleAnnotationNodeChange } from '../useTreeNodeActions';

interface TreeNodeProps extends TreeNodePropsCore<SceneEntity> {
  annotationMap: Map<SceneEntity, Set<Annotation>>;
  annotationIdsObject: Record<string, boolean>;
  isNodeChecked: IsNodeChecked;
  handleNodeChange: HandleNodeChange;
  handleAnnotationNodeChange: HandleAnnotationNodeChange;
}

export const TreeNode = ({
  data,
  isLeaf,
  depth,
  setOpen,
  isOpen,
  annotationMap,
  isNodeChecked,
  handleNodeChange,
  annotationIdsObject,
  handleAnnotationNodeChange,
}: TreeNodeProps) => {
  const annotations = useMemo(
    () => [...(annotationMap?.get(data) ?? new Set())],
    [data, annotationMap],
  );

  const initialOpen = useRef(false);

  useEffect(() => {
    if (!initialOpen.current) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isChecked = isNodeChecked(annotations, annotationIdsObject);

  const handleChange = () => {
    handleNodeChange(annotations, isChecked);
  };

  return (
    <div
      className={cn('flex', 'flex-row', 'items-center')}
      style={{ marginLeft: `${depth * 0.5}rem` }}
    >
      <div
        className={cn(
          !isLeaf ? 'i-skand-dropdownarrow cursor-pointer' : 'w-1em h-1em',
          'text-neutral-400',
          'text-3',
          'mr-2',
          !isOpen && 'rotate-270',
        )}
        onClick={() => setOpen(!isOpen)}
      />
      {data.entity.type === 'layerGroup' && (
        <LayerGroupNode group={data.entity} handleChange={handleChange} isChecked={isChecked} />
      )}
      {data.entity.type === 'layer' && (
        <LayerNode handleChange={handleChange} isChecked={isChecked} layer={data.entity} />
      )}
      {data.entity.type === 'annotation' && (
        <AnnotationNode
          annotation={data.entity}
          handleChange={handleAnnotationNodeChange}
          isChecked={annotationIdsObject[data.entity.id]}
        />
      )}
      {data.entity.type === 'annotationGroup' && (
        <AnnotationGroupNode
          group={data.entity}
          handleChange={handleChange}
          isChecked={isChecked}
        />
      )}
    </div>
  );
};
