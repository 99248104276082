import { cn } from '@/utils/classname';
import { useCallback, useState } from 'react';
import { Maybe, ReportPdfGenerateEvent } from '@/graphql/codegen/graphql';
import { useGenerateReportPdf } from '@/hooks/useGenerateReportPdf';

interface PdfReportStatusProps {
  pdfGenerateEvents: Array<Maybe<ReportPdfGenerateEvent>>;
  rowId: string;
}

export const PdfReportStatus = ({ rowId, pdfGenerateEvents }: PdfReportStatusProps) => {
  const [isFailureTooltipOpen, setIsFailureTooltipOpen] = useState(false);
  const { mutate: generateReportPdf } = useGenerateReportPdf(rowId);

  const handleGenerateReportPdf = useCallback(() => {
    generateReportPdf();
  }, [generateReportPdf]);

  const handleOpenFailureTooltip = () => {
    setIsFailureTooltipOpen(true);
  };

  const handleCloseFailureTooltip = () => {
    setIsFailureTooltipOpen(false);
  };

  const displayPdfReportStatus = () => {
    const lastEvent = pdfGenerateEvents[pdfGenerateEvents.length - 1] || {};
    const { status: lastEventStatus, errorMessage: lastEventErrorMessage } = lastEvent;

    switch (lastEventStatus) {
      case 'IN_PROGRESS':
        return (
          <div
            className={cn(
              'flex justify-start items-center gap-2 w-fit',
              'px-3 py-1',
              'border border-solid border-info-400 rounded bg-info-100',
            )}
          >
            <div className="i-skand-info inline color-info-400" />
            <p className="color-neutral-800 typo-text-s">In Progress</p>
          </div>
        );
      case 'SUCCESS':
        return (
          <div
            className={cn(
              'flex justify-start items-center gap-2 w-fit',
              'px-3 py-1',
              'border border-solid border-success-400 rounded bg-success-100',
            )}
          >
            <div className="i-skand-done inline color-success-400" />
            <p className="color-neutral-800 typo-text-s">Ready</p>
          </div>
        );
      case 'FAILED':
        return (
          <div
            className={cn(
              'flex justify-start items-center gap-2 w-fit',
              'px-3 py-1',
              'border border-solid border-alert-400 rounded bg-alert-100',
              'cursor-pointer relative',
            )}
          >
            <div className="i-skand-warn inline color-alert-400" />
            <p className="color-neutral-800 typo-text-s">Failed</p>
            <div className="i-skand-help" onMouseEnter={handleOpenFailureTooltip} />

            {isFailureTooltipOpen && (
              <div
                className={cn(
                  'absolute z-10 rounded p-1 top-7.2 left-20',
                  'border border-solid border-neutral-400 rounded bg-neutral-100',
                  'max-w-[800px]',
                  'overflow-hidden',
                )}
                onMouseLeave={handleCloseFailureTooltip}
              >
                <p className="max-h-256px w-320px overflow-y-auto whitespace-normal p-1 typo-text-s">
                  {lastEventErrorMessage ??
                    'There seems to be a problem with generating the PDF for this file. Please try again later.'}
                </p>
              </div>
            )}
            <div
              className="i-skand-undo absolute text-4 color-neutral-600 -right-6"
              onClick={handleGenerateReportPdf}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="flex flex-1 items-center justify-start whitespace-nowrap text-sm color-neutral-800 typo-text-s"
      onMouseLeave={handleCloseFailureTooltip}
    >
      {displayPdfReportStatus()}
    </div>
  );
};
