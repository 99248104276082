import { FindIcon } from '@/components/Icon';
import { setCreateReportCondition, useLayout } from '@/stores/layout';
import { Button, Input } from '@skand/ui';

interface FilterMenuProps {
  enabledFilterMenu: boolean;
  setEnabledFilterMenu: (value: boolean) => void;
}

export const Filter = ({ enabledFilterMenu, setEnabledFilterMenu }: FilterMenuProps) => {
  const projectId = useLayout(state => state.projectId);
  const condition = useLayout(state => state.createReportCondition);
  const storedTemplateFilterKeys = useLayout(state => state.storedTemplateFilterKeys);
  const storedLayerFilterKeys = useLayout(state => state.storedLayerFilterKeys);

  const hasFilterSelected = storedTemplateFilterKeys.size > 0 || storedLayerFilterKeys.size > 0;

  return (
    <div className="sticky top-0 z-1 w-full flex flex-none bg-white px-3 pt-3">
      <div className="flex-1">
        <Input
          disabled={!projectId}
          onChange={search => setCreateReportCondition({ search })}
          placeholder="Search individual or group annotation names"
          tail={<FindIcon />}
          value={condition.search}
        />
      </div>

      <div className="group/filter relative ml-2 flex flex-none">
        <Button
          className="px-5"
          filled={hasFilterSelected}
          onClick={() => setEnabledFilterMenu(!enabledFilterMenu)}
          size="s"
        >
          Filter
        </Button>
      </div>
    </div>
  );
};
