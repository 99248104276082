import { READ_USER_BY_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = () => ['READ_USER_BY_USER_ID'];

export const useUser = () => {
  const response = useQuery({
    queryFn: () => request(READ_USER_BY_CONTEXT),
    queryKey: getQueryKey(),
  });

  const user = useMemo(() => {
    return response.data?.readUserByContext ?? null;
  }, [response.data?.readUserByContext]);

  return { response, user };
};

useUser.getQueryKey = getQueryKey;
