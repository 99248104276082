import { AnnotationGroup } from '@/utils/entities';
import { cn } from '@/utils/classname';
import { CheckBox } from '@skand/ui';

export interface AnnotationGroupNodeProps {
  group: AnnotationGroup;
  isChecked: boolean;
  handleChange: () => void;
}

export const AnnotationGroupNode = ({
  group,
  isChecked,
  handleChange,
}: AnnotationGroupNodeProps) => {
  return (
    <div className={cn('flex justify-between items-center py-1 z-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <CheckBox checked={isChecked} onChange={handleChange} />
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{group.name}</p>
      </div>
    </div>
  );
};
